.wrapperInput,
.editorStyles,
.toolbarStyles {
  width: 100%;
  height: 100% !important;
  border-radius: 10px !important;
}

.editorStyles {
  padding: 5px 10px;
  width: 102% !important;
}

.wrapperInput {
  width: 98%;
}
