.select {
  border-radius: 35px !important;
  width: 90% !important;
}

.select .ant-select-selector {
  border-radius: 20px;
  border: none;
  box-shadow: none;
}

.FormInput {
  border: none !important;
  outline: none !important;
  width: 100%;
  box-shadow: none !important;
}

.FormInput:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.DatepickerInput {
  border: none !important;
  outline: none !important;
  min-width: 90% !important;
}

.option {
  display: flex;
  align-items: center;
}

.option .ant-select-selector {
  display: flex;
  align-items: center;
}

.flagIcon {
  width: 20px;
  margin-right: 10px;
}
.selectInput {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 35px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.NormalInput {
  display: flex !important;
  background-color: #fff;
  border-radius: 35px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  align-items: center;
  height: 48px;
  padding-left: 5px;
  padding-right: 5px;
}

.NormalInputPhone {
  display: flex !important;
  background-color: #fff;
  border-radius: 35px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  align-items: center;
  height: 48px;
  padding-left: 5px;
  padding-right: 5px;
}

.DateInput {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 35px;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 5px;
}
.innerDivlayout {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}
.divider {
  width: 100%;
}

.innerDiv {
  display: flex;
}

/* /////////////////////////// */

.phoneNumberInput {
  display: flex !important;
  align-items: center !important;
  padding: 0rem 1rem !important;
}
.selectCountryCode {
  display: flex !important;
  align-items: center !important;
}

.selectCountryCode > div > span {
  display: flex !important;
  align-items: center !important;
  padding-right: 8px;
}

.select > div > span {
  display: flex !important;
  align-items: center !important;
  padding-right: 8px;
}

.dividerInput {
  border: 1px solid #e8e8e8 !important;
  width: 2px;
  height: 20px;
  background-color: #e8e8e8;
}

.submitButton {
  background: #4e4dff;
  color: white;
  width: 95%;
  height: 50px;
  border-radius: 35px;
  border: none;
  margin-top: 20px;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
}

.mailIcon {
  margin-left: 1rem;
  font-size: 18px;
}

@media (max-width: 768px) {
  .phoneNumberInput {
    flex-direction: column !important;
    padding: 0 !important;
  }

  .NormalInputPhone {
    display: flex !important;
    background-color: #fff;
    border-radius: 25px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    align-items: center;
    height: 118px;
    width: 180px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .selectCountryCode {
    width: 100% !important;
    margin-bottom: 8px !important;
  }

  .FormInput {
    width: 100% !important;
  }

  .dividerInput {
    display: none !important;
  }
}

