.backBtn {
  position: absolute;
  top: 5%;
  left: 5%;
  z-index: 100;
  cursor: pointer;
  border-radius: 50%;
  border: none !important;
  height: 50px;
  width: 50px;
}
