.mainContainer {
  width: 100% !important;
  height: 100vh !important;
  overflow: hidden !important;
  background-color: #1c2226 !important;
  padding: 1rem !important;
  box-sizing: border-box !important;
  position: relative !important;
}

.container {
  display: flex !important;
  flex-direction: row !important;
  overflow: hidden !important;
  background-color: #1c2226 !important;
}

.conferenceCall {
  width: 70% !important;
  height: 80vh !important;
  margin: auto !important;
  background-color: #1c2226 !important;
  position: relative !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
}

.conferenceCall::-webkit-scrollbar {
  display: none !important;
}

.chat {
  width: 30% !important;
  height: 60vh !important;
  border-left: 1px solid #ccc !important;
  background-color: #1c2226 !important;
  padding: 1rem !important;
  box-sizing: border-box !important;
  position: relative !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
}

.chat .cc-messages-wrapper {
  border: 1px solid red !important;
}

.chat::-webkit-scrollbar {
  display: none !important;
}

.chatHeader {
  /* height: 100% !important; */
  width: 100% !important;
}

.heading {
  font-size: 1.5rem !important;
  margin-bottom: 1rem !important;
  text-align: center !important;
}

.conferenceID {
  font-size: 1rem !important;
  margin-bottom: 1rem !important;
}

.shareLink {
  font-size: 0.9rem !important;
  margin-bottom: 1rem !important;
  color: #007bff !important;
  word-wrap: break-word !important;
  display: none !important;
}

.showInfoButton {
  background-color: #007bff !important;
  color: #fff !important;
  border: none !important;
  padding: 0.5rem 1rem !important;
  cursor: pointer !important;
  margin-top: 1rem !important;
  text-align: center !important;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column !important;
    height: auto !important;
  }

  .conferenceCall {
    width: 100% !important;
    height: 50vh !important;
  }

  .chat {
    width: 100% !important;
    height: 40vh !important;
  }
}
