.topDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f5f4fa;
  position: sticky;
  top: 0;
  z-index: 10;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  margin-top: 0;
  padding-left: 15px;
  padding-right: 15px;
  /* border-radius: 8px; */
}

html {
  scroll-behavior: smooth;
}

.dashboardMainDiv {
  height: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
}
.searchDiv,
.topSearchDiv {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 10;
  background-color: white;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 35px;
  height: 45px;
  width: 300px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.searchInput {
  background-color: transparent;
  height: 80%;
  border: none;
  margin-right: 5px;
  width: 100%;
  outline: none;
  border-radius: 35px;
}
.filterIcon {
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  cursor: pointer;
}
.SearchIcon {
  margin-left: 5px;
}
.topCardsInfo {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 15px;
  margin-top: 1rem;
}

.topCardsInfo .card {
  border-radius: 10px;
  background: linear-gradient(
    97deg,
    #f0e6ff 0.26%,
    #ccf3ff 74.94%,
    #ccf3ff 89.32%
  );
  height: 90px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 20px;
}
.cardTop {
  color: #30263e;
  leading-trim: both;
  text-edge: cap;
  font-family: Google Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.cardBottom {
  color: #675f77;
  leading-trim: both;
  text-edge: cap;
  font-family: Google Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.42px;
  margin-top: 0.2rem;
}

.contentDiv {
  height: 100vh;
  width: 100%;
  margin-top: 1rem;
  display: flex;
  gap: 2%;
  flex: 1;
}

.mainContainerContent {
  height: calc(100vh - [Height of your topDiv]);
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
}
.leftdiv {
  width: 60%;
  height: 100vh;
}
.rightdiv {
  width: 38%;
  height: 100vh;
}
.tableContainer {
  box-shadow: none;
  border: 1px solid #e0e0e0;
}

.table {
  min-width: 650;
}

.searchField {
  margin-bottom: 20px;
  border-radius: 35px;
  background: #fff;
}

.showLess {
  cursor: pointer;
  color: #1976d2;
  margin-right: 10px;
}

.viewMore {
  cursor: pointer;
  color: #1976d2;
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.tableFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #dddddd;
  width: 100%;
}

.viewControls {
  display: flex;
}

.showLess {
  margin-right: 10px;
  cursor: pointer;
  color: #1976d2;
  border: none;
  background-color: white;
  background: white;
  color: #4e4dff;
  font-weight: bold;
  text-decoration: underline;
}

.viewMore {
  cursor: pointer;
  color: #1976d2;
  border: none;
  background-color: white;
  background: white;
  color: #4e4dff;
  font-weight: bold;
  text-decoration: underline;
}

.tableContainer {
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 24px;
  background-color: none !important;
  background: none !important;
  box-shadow: none !important;
  border: none !important;
  display: flex;
  align-items: center;
  flex-direction: column;
}


.tableContainerRow {
  border-radius: 8px;
  overflow: hidden;
  background-color: none !important;
  background: none !important;
  box-shadow: none !important;
  border: none !important;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.tableContainerRow {
  box-shadow: none;
  border: 1px solid #e0e0e0;
}

.customHeader {
  background-color: white;
  margin-bottom: 24px;
  border-radius: 15px;
  padding-left: 15px;
  padding-right: 15px;
  width: 95%;
}

.tableBodyHeader {
  padding-top: 1rem;
}

.table {
  width: 100%;
  border-collapse: collapse;
  background-color: white !important;
}

.tableHeader {
  padding: 15px;

  height: 20px !important;
  border-radius: 5px !important;
}
.tableHeader tr,
.tableHeader th {
  height: 20px !important;
  border-radius: 5px !important;
  border: none !important;
}

.tableBody {
  background-color: #ffffff;
  padding: 15px;

  height: 20px !important;
  border-radius: 5px !important;
}

.tableBody tr td {
  border-bottom: 1px solid #ddd;
}

.tableBody tr:last-child td {
  border-bottom: none;
}

.viewMore,
.showLess {
}

.pagination {
}

.productImage {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}

.rating div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-radius: 20px;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.05) 0%,
      rgba(255, 255, 255, 0.05) 100%
    ),
    rgba(48, 38, 62, 0.9);
  backdrop-filter: blur(10px);
  color: white;
  /* width: 50px; */
  font-weight: bold;
  padding-left: 1rem;
  padding-right: 1rem;
}
.rating {
  display: flex;
  align-items: center;
  justify-content: center;
}

.reschedule {
  background: #4e4dff;
}
.rescheduled {
  background: #30263e;
}
.cancel {
  background: #30263e;
}
.reschedule,
.cancel,
.rescheduled {
  color: white;
  font-weight: bold;
  border-radius: 20px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  border: none !important;
  min-width: 100px;
}
.reschedule:hover {
  color: #4e4dff !important;
  background-color: #fff !important;
  border: 1px solid #d8d8d8 !important;
}
.cancel:hover {
  color: #30263e !important;
  background-color: #fff !important;
  border: 1px solid #d8d8d8 !important;
}
.actionButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
}

.actionButtons_ {
  display: flex;
  align-items: center;
  justify-content: center ;
  gap: 0px !important;
  margin-left: 10px;
}

.eye {
  color: #97929e;
  border: none !important;
  box-shadow: none !important;
}

.eye1 {
  border: none !important;
  box-shadow: none !important;
}

.refundButton {
  background-color: #4e4dff;
  border-radius: 35px;
  height: 40px;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
}

.refundButtonNo {
  background-color: #30263e;
  border-radius: 35px;
  height: 40px;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
}

.statusUpcoming {
  background-color: #fef8eb;
  color: #faa800;
}

.statusInProgress {
  background-color: #f1f1ff;
  color: #4e4dff;
}

.statusCompleted {
  background-color: #eefbf6;
  color: #35ce92;
}

.statusCancelled {
  background-color: #fff1f1;
  color: #ff4d4d;
}

.statusText {
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  border-radius: 15px;
  height: 30px;
}

.profilePicture {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: -10px;
  border: 2px solid white;
}

.profilePicturesContainer {
  display: flex;
}

.totalSeatInfo {
  margin-left: 15px;
  font-weight: bold;
}

.bookedSeatsText {
  color: #97929e;
  font-weight: normal;
}

.seatsContainer {
  display: flex;
  align-items: center;
}

.customer_avatar {
  display: flex;
  align-items: center;
  gap: 8px;
}

.customer_avatar_placeholder {
  width: 20px;
  height: 20px;
  background-color: #4e4dff;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 15px;
  font-weight: bold;
}

.avatarDivInfo {
  display: flex;
  gap: 2rem;
}

.name {
  font-weight: bold;
  font-size: 16px;
}

.avatarDivInfo div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatarDivInfo .saveIcon {
  width: 12px;
  margin-left: 0.5rem;
  cursor: pointer;
}

.social_media_icons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  padding: 0.5rem 0.5rem;
}

.infoDiv {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  justify-content: flex-start;
  align-items: flex-start;
}

.nameDiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.info {
  color: #97929e;
}

.productList {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  background-color: white !important;
  background: white !important;
}

.productListItem {
  display: flex;
  background: #ffffff;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
  flex-direction: column;
}

.productListItem .ant-checkbox-wrapper {
  margin-right: 16px;
}

.productListItem .ant-input {
  border-radius: 20px;
  padding: 10px;
}
.formInput,
.formSelect,
.formTextArea,
.formInput:hover,
.formInput:active,
.formInput:focus,
.formInput:visited,
.formSelect,
.formSelect:hover,
.formSelect:active,
.formSelect:focus,
.formSelect:visited,
.formTextArea,
.formTextArea:hover,
.formTextArea:active,
.formTextArea:focus,
.formTextArea:visited {
  width: 100%;
  background-color: white !important;
  border-radius: 35px !important;
  height: 40px !important;
  font-size: 14px !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  border: none !important;
  display: flex !important;
  align-items: center !important;
  /* justify-content: center !important; */
  padding: 0rem 1rem !important;
  border: 3px solid #f5f4f5 !important;
}


.productListItem .ant-input[disabled] {
  background: #f5f5f5;
  opacity: 1;
}

.selectAllProductsOption {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.selectAllProductsOption .ant-radio-wrapper,
.selectAllProductsOption .ant-checkbox-wrapper {
  margin-right: 8px;
}

.selectAllProductsOption label {
  margin: 0;
  font-weight: 500;
}

.customRadioBtn .ant-radio-inner {
  border-color: #8c8c8c;
}

.customRadioBtn .ant-radio-inner::after {
  background-color: #4d90fe;
}

.customRadioBtn .ant-radio-checked .ant-radio-inner {
  border-color: #4d90fe;
}

.customCommissionInput .ant-input {
  border: 1px solid #d9d9d9;
}

.checkbox {
  min-width: 48% !important;
}

.expanded {
  /* border: 1px solid red !important; */
  border-radius: 35px !important;
  border-bottom: 0px !important;
}

.expanded td {
  border-bottom: 0px !important;
  margin-top: 1rem;
}

.expanded td:first-child {
  border-radius: 10px 0px 0px 10px !important;
  border-bottom: 0px !important;
}

.expanded td:last-child {
  border-radius: 0px 10px 10px 0px !important;
  border-bottom: 0px !important;
}

.expandedRow {
  /* border: 1px solid red; */
  background-color: #fef1cc;
  border-radius: 0px 0px 10px 10px;
}

.expandedRow > div {
  border-radius: 10px 10px 10px 10px !important;
  background-color: white;
}

.expandedRow1 {
  /* border: 1px solid red; */
  border-radius: 0px 0px 10px 10px;
}

.expandedRow1 > div {
  border-radius: 10px 10px 10px 10px !important;
  background-color: white;
}

.expandedRowInner {
  background-color: white;
  border-bottom: 2px solid #f5f4fa;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.8rem 0.5rem 0.8rem;
}


.expandedRowInner:last-child {
  border-bottom: none !important;
}

.expandedSpan {
  font-weight: bold;
  color: #30263e;
  font-size: 16px;
}

.addButton {
  background-color: #4e4dff;
  color: white;
  border: none;
  border-radius: 35px;
  margin-right: 10px;
  cursor: pointer;
  min-width: 80px;
}
.addButton:hover,
.addButton:active {
  background-color: #ffffff !important;
  color: #4e4dff !important;
  border: 1px solid #4e4dff;
  border-radius: 35px;
  margin-right: 10px;
  cursor: pointer;
}

.sendQuoteBtn {
  background-color: #4e4dff;
  margin-top: 20px;
  height: 35px;
  width: 120px;
  color: white;
  border: none;
  border-radius: 35px;
  margin-right: 10px;
  cursor: pointer;
  text-align: center;
}

.rejectQuote {
  background-color: #30263e;
  margin-top: 20px;
  height: 35px;
  width: 120px;
  color: white;
  border: none;
  border-radius: 35px;
  margin-right: 10px;
  cursor: pointer;
  text-align: center;
}

.addButtonRev {
  background-color: #31263e;
  color: white;
  border: none;
  border-radius: 35px;
  margin-right: 10px;
  cursor: pointer;
  min-width: 80px;
}
.addButtonRev:hover,
.addButtonRev:active {
  background-color: #fff !important;
  color: #30263e !important;
  border: 1px solid #30263e !important;
  border-radius: 35px;
  margin-right: 10px;
  cursor: pointer;
}

/* YourStyles.module.css */
.customSelect {
  width: 100%; /* Adjust width as needed */
}

.customDropdown {
  z-index: 1000; /* Make sure it's high enough */
}

.displayColor {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
  box-shadow: 0px 10px 30px 0px #30263e0f;
  border: 2px solid #d8d8d8;
}

.statusDiv {
  display: flex ;
  width: 100% ;
  border-radius: 10px ;
  align-items: center ;
  justify-content: center ;
}

.actionButtons {
  display: flex;
  align-items: center;
  gap : 0.3rem;
}

.acceptIcon {
  color : green;
  font-weight: bold;
  border: none !important;
}

.rejectIcon {
  color : red;
  font-weight: bold;
  border: none !important;
}

.sendQuote {
  color : #4d90fe;
  font-weight: bold;
  border: none !important;
}

.formStyle {
  margin-top : 20px !important;
}

.chatUserBtn {
    background-color: #4e4dff;
    height: 35px;
    color: white;
    border: none;
    border-radius: 35px;
    margin-right: 10px;
    cursor: pointer;
    text-align: center;
}

.formClass {
  margin-top: 0 !important;
}

.contractDiv {
  border-radius: 10px;
  padding: 10px;
  margin-right: 10px;
  background: #f2f0ff;
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  height: 130px ;
  justify-content: space-around;
  margin-bottom: 10px;
}

.contractDiv_cp {
  border-radius: 10px;
  padding: 10px;
  margin-right: 10px;
  background: #f2f0ff;
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  height: 50px ;
  justify-content: space-around;
  margin-bottom: 10px;
}

.buttonFilled {
  background-color: #4e4dff;
  color: white;
  border: none;
  border-radius: 35px;
  margin-right: 5px;
  cursor: pointer;
}

.buttonDark {
  color: white;
  font-weight: bold;
  background-color: #30263e;
  border-radius: 20px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  border: none !important;
}