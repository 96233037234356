.input {
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 35px;
}
.select {
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 35px;
  width: 170px !important;
  /* border: 1px solid red !important; */
}
.date {
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.addButton {
  margin-top: 20px;
  width: 200px;
  height: 50px;
  background-color: #31263e;
  color: white;
  border-radius: 35px;
  font-size: 18px;
}
.addButton:hover,
.addButton:active,
.addButton:focus {
  margin-top: 20px;
  width: 200px;
  height: 50px;
  background-color: white;
  color: #31263e !important;
  border-radius: 35px;
  font-size: 18px;
  border: #31263e 1px solid !important;
}
