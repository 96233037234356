.verificationContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: relative;
  z-index: 1;
  padding: 20px;
  text-align: center;
}

.backgroundVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  transition: opacity 1s;
}

.messageContainer {
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 24px;

  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(6.5px);
  -webkit-backdrop-filter: blur(6.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.successMessage,
.errorMessage {
  font-size: 16px;
  margin-top: 16px;
  font-weight: bold;
}

.successMessage {
  color: #52c41a;
}

.errorMessage {
  color: #ff4d4f;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

.retryContainer {
  margin-top: 20px;
}

.retryBtn {
  background-color: #31263e !important;
  border-radius: 35px !important;
  width: 100%;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
}

.retryBtn:hover {
  background-color: #31263e !important;
}

.buttonsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 1rem;
}

.continueBtn {
  width: 100%;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 35px !important;
  background-color: #1677ff !important;
  color: white !important;
}

.continueBtn:hover {
  background-color: #1677ff !important;
  color: white !important;
}
