.eventCardContainer {
  padding: 5px;
  border-radius: 20px;
  overflow: hidden;
  width: 550px;
  height: 350px;
}

.eventCardLink {
  text-decoration: none;
  color: inherit;
}

.eventCard {
  position: relative;

  border-radius: 20px;
}

.eventImage {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-radius: 20px;
  max-height: 350px;
  position: relative;
}

.eventImage::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 20px;
}
.eventInfo {
  position: absolute;
  bottom: 20px;
  left: 16px;
  right: 16px;
  color: white;
}

.eventDate {
  padding: 4px 8px;
  display: inline-block;
  border-radius: 12px;
  margin-bottom: 8px;
  border-radius: 40px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(17px);
  color: black !important;
  font-weight: bold;
}

.eventTitle {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.2;
  margin: 0;
}

.commentsSection {
  max-height: 90vh;
  overflow-y: auto;
  padding: 20px;
  background-color: #f9f9f9;
}

.commentInputContainer {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.commentInput {
  margin-right: 8px;
  flex: 1;
  border-radius: 8px;
}

.avatarImage {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-left: 10px;
  object-fit: cover;
}

.avatarPlaceholder {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3498db;
  color: white;
  font-size: 20px;
}

.commentTextContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.commentAuthor {
  font-size: 16px;
  font-weight: bold;
}

.commentContent {
  font-size: 14px;
  color: #555;
}

.commentTime {
  background-color: #f2f0f3;
  border-radius: 15px;
  font-weight: bold;
  padding: 5px 10px;
  color: gray;
  text-align: center;
}

.commentContainer {
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.closeButton {
  background: transparent;
  border: none;
  cursor: pointer;
}
