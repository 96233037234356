.react-day-picker-weekday {
    color: #888;
  }
  
  .react-day-picker-day {
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s;
  }
  
  .react-day-picker-day--selected {
    background-color: #4285f4;
    color: #ffffff;
  }
  
  .react-day-picker-day--outside {
    color: #e0e0e0;
  }
  
  .bookingPageContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    width: fit-content !important;
  }
  
  .bookingMainContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
    width: fit-content;
  }
  
  /* General styling for the container and date picker */
  .date-picker-container {
    width: 90%;
    background-color: #ffffff;
    border-radius: 16px;
    overflow: hidden; /* Hide overflow to maintain the rounded corners */
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    padding: 0 20px;
  }
  .date-picker-containerPar {
    width: 100%;
    border-radius: 16px;
    overflow: hidden;
    width: 600px !important;
    max-width: 90vw;
  }
  
  /* Top buttons with rounded corners */
  .date-picker-top-buttons {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    border-bottom: 1px solid #e5e5e5;
  }
  
  .date-picker-top-buttons button {
    margin-right: 8px;
    border: none;
    border-radius: 12px;
    background-color: #f5f5f5;
    color: #7f7f7f;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .date-picker-top-buttons button:last-child {
    margin-right: 0;
  }
  
  .date-picker-top-buttons button:hover {
    background-color: #e5e5e5;
  }
  
  .date-picker-top-buttons button.active {
    background-color: #4285f4;
    color: #ffffff;
  }
  
  /* Styles specific to the date picker */
  .react-day-picker {
    border: none;
  }
  
  .react-day-picker-weekday {
    /* color: #7f7f7f; */
    font-size: 14px;
  }
  
  .react-day-picker-day {
    font-size: 14px;
    transition: background-color 0.2s, color 0.2s;
  }
  
  .react-day-picker-day--today {
    color: #4285f4; /* Highlight today's date */
  }
  
  .react-day-picker-day--selected {
    background-color: #4285f4;
    color: #ffffff;
  }
  
  .react-day-picker-day--outside {
    color: #e5e5e5;
  }
  
  .time-slots-container {
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(100px, 1fr)
    ); /* Adjust as needed */
    gap: 10px; /* Adjust spacing between buttons */
  }
  
  .time-slots-containerNull {
    display: flex;
  }
  
  .time-slot-button {
    background-color: white;
    color: #30263e;
    border: rgba(48, 38, 62, 0.08) 1px solid;
    border-radius: 35px;
    padding: 10px;
    font-size: 14px;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s ease;
    font-weight: 500;
  }
  .time-slot-button:hover,
  .time-slot-button:focus,
  .time-slot-button::selection {
    background-color: #4e4dff;
    color: white;
    border: none;
    border-radius: 35px;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s ease;
    font-weight: bold;
  }
  
  .selected {
    background-color: #4e4dff;
    color: white;
    border: none;
    border-radius: 35px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s ease;
    font-weight: bold;
  }
  
  .booked {
    border: none;
    color: #8f8a97;
    font-weight: bold;
    background-color: rgba(48, 38, 62, 0.08) !important;
  }
  .time-slot-button:hover {
    background-color: #3333cc; /* Change color on hover */
  }
  
  .time-slot-button:disabled {
    background-color: #ccc; /* Disable button color */
    cursor: not-allowed;
  }
  
  .booked {
    background-color: #ccc; /* Change color for booked slots */
  }
  .css-1u23akw-MuiButtonBase-root-MuiPickersDay-root.Mui-selected,
  .css-jgls56-MuiButtonBase-root-MuiPickersDay-root.Mui-selected,
  .css-15a0tn7.Mui-selected,
  .css-6exafu.Mui-selected {
    background-color: #4e4dff !important;
  }
  
  .bold {
    font-weight: bold;
  }
  .semibold {
    font-weight: 500;
  }
  .boldBig {
    font-weight: bold;
  }
  .currency {
    color: #30263e80;
  }
  .titlePrice {
    color: #4e4dff;
  }
  .date-picker,
  .css-1q04gal-MuiDateCalendar-root,
  .css-14iq4xa-MuiDayCalendar-root,
  .css-14iq4xa-MuiDayCalendar-root .css-i5q14k-MuiDayCalendar-header,
  .css-14iq4xa-MuiDayCalendar-root .css-12p2adl-MuiDayCalendar-monthContainer,
  .css-1vs7z2v-MuiYearCalendar-root,
  .css-1n2mv2k,
  .css-1h73gvd {
    /* border: 1px solid red; */
    width: 100% !important;
  }
  .css-flbe84-MuiDayCalendar-weekContainer,
  .css-i5q14k-MuiDayCalendar-header,
  .css-1h73gvd,
  .css-mvmu1r,
  .css-1n2mv2k {
    justify-content: space-between !important;
  }
  .css-nk89i7-MuiPickersCalendarHeader-root,
  .css-1dozdou {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end !important;
    align-items: center;
    margin-left: 0px !important;
    padding-left: 5px !important;
    margin-bottom: 15px !important;
  }
  .css-cyfsxc-MuiPickersCalendarHeader-labelContainer,
  .css-16j77m4 {
    margin-right: 0 !important;
    margin-left: 20px;
  }
  .css-kg9q0s-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button,
  .css-1nkg345-MuiButtonBase-root-MuiIconButton-root-MuiPickersArrowSwitcher-button,
  .css-11wxb,
  .css-1fklenr {
    border: #d8d7db 1px solid !important;
    margin-left: 0px !important;
    margin-right: 2px !important;
    height: 28px;
    width: 28px;
  }
  .css-1vooibu-MuiSvgIcon-root {
    fill: #30263e;
    font-weight: bold !important;
  }
  .css-9reuh9-MuiPickersArrowSwitcher-root {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 60px;
  }
  .css-xb7uwb-MuiPickersArrowSwitcher-spacer {
    width: 0px !important;
  }
  .css-sf5j28-MuiButtonBase-root-MuiIconButton-root-MuiPickersCalendarHeader-switchViewButton,
  .css-12mkn7b-MuiButtonBase-root-MuiIconButton-root-MuiPickersCalendarHeader-switchViewButton,
  .css-sldnni {
    display: none !important;
  }
  .css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel,
  .css-10qkmc3 {
    color: #4e4dff !important;
    font-weight: bold !important;
  }
  
  .infoContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    width: 100%;
  }
  
  .serviceBookingInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;
    padding: 0;
    margin: 0;
  }
  
  .startContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    margin-top: 0;
  }
  
  .textdivider {
    flex: 1;
    min-width: 0;
    width: 100%;
  }
  
  .notesBox {
    width: 98%;
    padding: 20px;
    border: none;
    outline: none;
    background-color: white;
    margin-top: 10px;
    border-radius: 15px;
    box-shadow: 0px 5px 8px 0px rgba(48, 38, 62, 0.05);
  }
  
  .notesBox:hover,
  .notesBox:focus {
    box-shadow: 0px 5px 8px 0px rgba(48, 38, 62, 0.2) !important;
  }
  
  .paymentContainer {
    width: 400px;
    max-width: 90vw;
    border-radius: 16px;
    background-color: #ebeaf1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    
  }
  
  .paymentTop {
    background-color: white;
    box-shadow: 0px 8px 24px 0px rgba(48, 38, 62, 0.075);
    padding: 30px 20px;
    border-radius: 16px;
    border-bottom-right-radius: 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 95%;
    gap: 40px;
  }
  
  .paySelection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    gap: 10px;
  }
  
  .payTypeSelection {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
    margin-right: 20px;
    margin-top: 10px;
  }
  
  .payTypeBtn {
    width: 115px;
    height: 40px;
    padding: 8px 12px;
    background-color: white;
    color: #30263e;
    font-size: 14px;
    border-radius: 50px;
    cursor: pointer;
    border: none;
    outline: none;
    box-shadow: 0px 10px 16px 0px rgba(48, 38, 62, 0.075);
    white-space: nowrap;
  }
  
  .payActiveBtn {
    background-color: #4e4dff;
    color: white;
  }
  
  .card-col {
    gap: 0 !important;
  }
  
  .card-info {
    box-shadow: none !important;
  }
  
  .addCardBtn {
    margin-left: 10px;
  }
  
  .applyBtn {
    width: 90%;
    min-width: 90%;
    margin-top: 10px;
    padding: 4px 12px;
    padding-left: 20px;
    max-width: 70vw;
  }
  
  .useBtn {
    border-radius: 20px;
  }
  
  .couponheader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 97%;
  }
  
  .applyText {
    background-color: transparent;
    color: #4e4dff;
    border: none;
    font-weight: bold;
    font-size: 16px;
    padding: 4px 16px;
    border-left: 1px solid #aaa;
    height: 100%;
    width: 80px;
    cursor: pointer;
  }
  
  .applyCenter {
    padding-left: 20px;
    width: 200px;
    max-width: 35vw;
  }
  
  .useCenter {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 3px;
    padding-left: 20px;
    width: 200px;
    max-width: 35vw;
  }
  
  .paymentBottom {
    margin: 0 auto;
    margin-top: 30px;
    width: 90%;
    display: flex;
    flex-direction: column;
  }
  
  .costHeader {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    height: auto;
    width: 100%;
  }

  
  .costHeader p {
    margin: 0;
    margin-top: 15px;
  }
  
  .grayText {
    color: #8d8897;
  }
  
  .paySubmitBtn {
    height: 40px;
    font-weight: 500;
    font-size: 16px;
    width: 100%;
    margin: 30px 0;
  }
  
  @media (max-width: 1100px) {
    .infoContainer {
      width: fit-content;
    }
  }
  