.addButton {
  margin-top: 30px;
  margin-right: 0px;
  width: 150px;
  height: 35px;
  border-radius: 40px;
  border-color: #000000;
  background-color: #000000;
  color: white;
  padding: 19.5px, 50px, 19.5px, 50px;
  font-style: Bold;
  font-size: 18px;
  cursor: pointer;
}
.defaultButton {
  width: 115px;
  height: 28px;
  border-radius: 40px;
  border: none;
  border-color: gray;
  background-color: gray;
  color: white;
  padding: 19.5px, 50px, 19.5px, 50px;
  font-style: Bold;
  font-size: 12px;
  cursor: pointer;
  margin-top: 10px;
}
.defaultButtonBlue {
  width: 115px;
  height: 28px;
  border-radius: 40px;
  border: none;
  border-color: #1677ff;
  background-color: #1677ff;
  color: white;
  padding: 19.5px, 50px, 19.5px, 50px;
  font-style: Bold;
  font-size: 12px;
  cursor: pointer;
  margin-top: 10px;
}

.addressBoxMainContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-right: 1px solid #8080804d;
}

.addressLineContainer {
  white-space: initial;
  /* display: flex; */
  display: inline-block;
  width: 100%;
}

.labelConatiner {
  font-size: 18px;
  width: 100%;
  display: flex;
  align-items: start;
  color: grey;
  justify-content: start;
  margin: 20px 0px;
}

.locationDrawer,
.locationDrawer .ant-drawer {
  box-shadow: none !important;
}
