.dashboardContainer {
    background: #f5f4fa !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: fit-content;
    padding-bottom: 100px;
}

.contentDiv {
    margin-top: 8rem;
    width: 80%;
    height: fit-content;
}

.gridItem {
    padding-top: 0 !important;
    padding-left: 30px !important;
}

.gridItem_main {

    padding-top: 0 !important;
    padding-left: 0 !important;
}

.cardStyleLight {
    height: 110px;
    width: 152px;
    border-radius: 12px !important;
    margin: 8px;
}

.cardStyleDark {
    height: 110px;
    width: 152px;
    border-radius: 12px !important;
    margin: 8px;
    background: #30263E !important;
}

.gradient_icon {
    font-size: 30px;
    /* or any desired size */
    /* color: linear-gradient(106.87deg, #BA6AFF 0.56%, #67D1FF 75.66%) !important; */
    color: #fff;
}

.cardContentDiv {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 90%;
    margin-bottom: 15px;
    /* align-items: center; */
}

.followers {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 15px;
    margin-bottom: 20px;
}

.sales {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 15px;
    margin-left: 15px;
}

.cardText {
    width: 40%;
    font-weight: 700;
    color: white;
}

.followersText {
    width: 50%;
    font-weight: 700;
    color: white;
    margin-right: 10px;
}

.cardTextLight {
    display: flex;
    flex-direction: column;
    width: 75%;
    font-weight: 700;
    color: #30263E;
    opacity: 70%;
}

.headerText {
    font-weight: 500;
    font-size: 20px;
    color: #30263E;
}

.headerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.seeAllButton {
    background: transparent;
    border-radius: 40px;
    border: 1px solid #30263E1A
}

.eventCardContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.iconButton {
    background: linear-gradient(17deg, rgb(229, 225, 251) 0%, rgb(221, 233, 250) 50%);
    height: 25px;
    width: 25px;
    margin-left: 5px !important;

}

.cardHeader {
    margin: 0 !important;
    padding: 0px !important;
    padding-left: 10px !important;
    padding-top: 10px !important;
}

.divider {
    margin: 0 !important;
    width: 85%;
}

.divider_rt {
    margin: 15px !important;
    margin-left: 50px !important;
    width: 90%;
}

.profilePictureCard {
    width: 30px;
    height: 30px;
    border-radius: 30%;
    border: 2px solid white;
    box-shadow: 0px 10px 30px 0px #30263e0f;
}

.profilePictureCard_ {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid white;
    box-shadow: 0px 10px 30px 0px #30263e0f;
}

.profilePictureCard_rt {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid white;
    box-shadow: 0px 10px 30px 0px #30263e0f;
}


.profilePictureDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    /* border-color: aqua;
    border: 2px; */
}

.cardContent {
    padding-top: 0 !important;
    padding-left: 10px !important;
    margin-top: 0 !important;
}


.startButton {
    color: white;
    background: #4e4dff;
    font-weight: bold;
    border-radius: 20px;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    border: none !important;
    margin-right: 10px;
}

.startButton:hover {
    color: blue !important;
    background-color: #ffffff;
}

.endButton {
    color: white;
    background: #30263e;
    font-weight: bold;
    border-radius: 20px;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    border: none !important;

}

.endButton:hover {
    color: blue !important;
    background-color: #ffffff;
}


.recentTxCard {
    background: transparent !important;
    box-shadow: none !important;
    margin-top: 10px;
}

/* CSS for Grafana */


.Statiscsmaincontainer {
    display: flex;
}

.Statisticsmain {
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px;
    width: 450px;
    gap: 0.5rem;
}

.Statisticsmain>div {
    border-left: 3px solid #d6d4d8;
    padding: 10px;
}

.Statisticsmain>p {
    margin: 0;
    color: black;
    font-size: 18px;
    font-weight: bold;
}

.statistics {
    display: flex;
    align-items: center;
    gap: 10px;
}

.statistics img {
    height: 30px;
}

.statistics p {
    margin: 0;
    color: #333;
    font-size: 16px;
}

.Statiscsmaincontainer {
    display: flex;
}

.topCardsInfo {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 15px;
    margin-top: 1rem;
}