antTabs :global(.ant-tabs .ant-tabs-tab + .ant-tabs-tab) {
  width: 100px;
  align-items: center;
  justify-content: center;
}

.customLayoutBG {
    background-color: #f5f4fa !important;
    padding : 80px
  }
  
  .parentContentdiv {
    display: flex;
    gap: 1rem;
  }

  .parentContainerdiv {
    display: flex;
    gap: 1rem;
  }
  
  .breadcrumbItem:hover {
    cursor: pointer;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .serviceHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .actionsContainerMain {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  
    margin-right: 1rem;
  }
  
  .gradientText {
    background-image: linear-gradient(114.95deg, #8800ff 0.76%, #00b3ff 47.23%);
    -webkit-text-fill-color: transparent;
  }
  
  .buttonFilled {
    background-color: #4e4dff;
    color: white;
    border: none;
    border-radius: 35px;
    margin-right: 5px;
    cursor: pointer;
  }
  
  .buttonTransparent {
    border: 1px solid #cecbd4;
    color: #30263e;
    background-color: transparent;
    background: transparent;
    font-weight: bold;
    border-radius: 35px;
  }
  
  .profilePicture {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: -10px;
    border: 2px solid white;
    margin-right: 1rem;
  }
  
  .profilePictureCard {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      margin-right: -10px;
      border: 2px solid white;
      box-shadow: 0px 10px 30px 0px #30263e0f;
  }
  
  .profilePicturesContainer {
    display: flex;
  }
  
  .dropdownDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  .placeholderText {
    color: #30263e;
    font-weight: bold;
  }
  
  .dropdownMain {
    background-color: #ffffff;
    border-radius: 35px;
  }
  
  .parentContentdiv {
    display: flex;
    gap: 1rem;
  }
  
  .cardContainer {
    margin-top: 20px;
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .card {
    min-width: 650;
  }
  
  .customHeader {
      background-color: white;
      margin-bottom: 24px;
      border-radius: 15px;
      padding-left: 15px;
      padding-right: 15px;
      width: 95%;
  }


  
  .cardBody {
      background-color: #ffffff;
      padding: 15px;
      height: 80px !important;
      border-radius: 5px !important;
  }
  
  .cardBody_freelancer {
    background-color: #ffffff;
    padding: 15px;
    height: 55px !important;
    border-radius: 5px !important;
  }
  
  .customHeader {
    background-color: white;
    margin-bottom: 24px;
    border-radius: 15px;
    padding-left: 15px;
    padding-right: 15px;
    width: 95%;
  }
  


  
  .separator {
    pointer-events: none !important;
    cursor: default !important;
    margin-top: 25px;
    color: grey;
    opacity: 50%;
  }
  
  .profilePictureDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: aqua;
    border: 2px;
  }
  
  .cardHeaders {
    margin-top: 15px;
    font-size: 18px;
    font-weight: bold;
  }
  
  .cardHeaders1 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 2px;
  }
  
  .cardHeaderDetails {
    font-size: 10px;
    font-weight: 400;
    margin-top: 5px;
    color: #30263e;
    opacity: 50%;
  }
  
  .cardHeaderDivMain {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
  }
  
  .prodNameDiv {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .displayColor {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-right: 10px;
      object-fit: cover;
      box-shadow: 0px 10px 30px 0px #30263e0f;
      border: 2px solid #d8d8d8;
  }
  
  .descriptionContainer:hover {
      overflow: visible;
      white-space: normal;
      max-height: none;
  }
  
  .displayColorInfo {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 10px;
      object-fit: cover;
      box-shadow: 0px 10px 30px 0px #30263e0f;
      border: 2px solid #d8d8d8;
  }
  
  .toolTipRow {
      display: flex;
      align-items: center;
      font-family: Google Sans;
      font-size: 14px;
      font-weight: 500;
      padding: 3px;
  }
  
  .inventoryCount {
      width: 32px;
      height: 24px;
      display: flex;
      border-radius: 5px;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 8px 24px 0px #422f9b1a;
      background-color: #ffffff;
      font-size: 14px;
      font-weight: 700;
      color: #4e4dff;
      letter-spacing: -0.01em;
  }
  
  .toolTipMain {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 10px;
  }
  
  .toolTipDiv {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center
  }
  
  .infoIcon {
      margin-top: 7px;
      margin-left: 5px
  }
  
  .colorChip {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .buttonDark {
    color: white;
    font-weight: bold;
    background-color: #30263e;
    border-radius: 20px;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    border: none !important;
  }
  
  
  .addButton {
    background-color: #4e4dff;
    color: white;
    border: none;
    border-radius: 35px;
    margin-right: 5px;
    cursor: pointer;
    min-width: 80px;
  }
  .addButton:hover,
  .addButton:active {
    background-color: #ffffff !important;
    color: #4e4dff !important;
    border: 1px solid #4e4dff;
    border-radius: 35px;
    margin-right: 5px;
    cursor: pointer;
  }
  
  .eye {
    color: #97929e;
    border: none !important;
    box-shadow: none !important;
  }
  
  .countingSpan {
    background: white;
    padding: 0.1rem 0.5rem;
    border-radius: 35px;
    color: #30263e;
    font-weight: bold;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin-left: 0.2rem;
  }
  
  .contractDiv {
    border-radius: 10px;
    padding: 10px;
    margin-right: 10px;
    background: #f2f0ff;
    display: flex;
    flex-direction: column;
    margin-left: 5px;
    height: 130px ;
    justify-content: space-around;
    margin-bottom: 10px;
  }

  .profileInfo p,
  .stats {
    text-align: left;
  }

  .profileInfo p {
    margin: 5px 0;
  }

  .profileInfo {
    margin-left: 20px;
  }

  .profileInfo {
    margin-left: 10px;
    flex: 1;
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1rem;
    padding: 1rem;
    margin: 0 auto;
    margin-bottom: 300px;
  }
  @media (max-width: 850px) {
    .content {
      padding: 0 !important;
    }
    .cardWrapper {
      max-width: 100%;
    }
  }
  
  @media (max-width: 768px) {
    .box {
      flex: 1 1 calc(50% - 20px);
    }
  
    .content {
      padding: 0 !important;
    }
  
    .cardWrapper {
      max-width: 100%;
    }
  }
  
  @media (max-width: 480px) {
    .box {
      flex: 1 1 100%;
    }
  
    .content {
      padding: 0 !important;
    }
  }  

  .profileContainer {
    padding: 10px;
    /* max-width: 1200px; */
    height: 100vh;
    margin: 0 auto;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #f5f4fa !important;
  }
  
  .profileHeader {
    margin-bottom: 10px;
    border-radius: 15px;
    margin-top: 4.5rem;
  }
  
  .profileLeft {
    display: flex;
    flex-direction: column;
  }
  
  .profileDetails {
    display: flex;
    align-items: center;
  }
  
  .profileInfo {
    margin-left: 20px;
  }
  
  .nameContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    justify-content: flex-start;
  }
  
  .profileInfo p {
    margin: 5px 0;
  }
  
  .displayName {
    font-size: 20px;
    font-weight: normal;
  }
  
  .verifiedIcon {
    color: #1da1f2;
    margin-left: 5px;
  }
  
  .collabButton {
    background: #e8f5e9;
    color: #388e3c;
    border: none;
    cursor: default;
    border-radius: 35px;
  }
  
  .stats {
    display: flex;
    gap: 15px;
    margin: 10px 0;
    color: #31263e;
  }
  
  .tabContainer {
    margin-top: 1rem;
    overflow-x: auto !important;
  }
  
  .tabsContainer {
    overflow-x: auto !important;
  }
  
  .postsContainer {
    margin-top: 20px;
    padding-bottom: 1rem;
    max-height: 550px;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none;
  }
  
  .postsContainer::-webkit-scrollbar {
    display: none;
  }
  
  .coverImage {
    height: 300px;
    object-fit: cover;
    width: 100%;
    border-radius: 15px;
    min-width: 300px;
  }
  
  .cardImageWrapper {
    position: relative;
  }
  
  .categoryTag {
    position: absolute;
    bottom: -10px;
    left: 10px;
    background: white;
    color: #31263e;
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 35px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    font-weight: bold;
  }
  
  .truncate {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
  
  .serviceTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .serviceName {
    font-weight: bold;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .serviceCategory {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }
  
  .currency {
    color: #888;
  }
  
  .servicePrice {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
  }
  
  .serviceDesc {
    color: #888;
    font-size: 12px;
  }
  
  .cardWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    min-width: 300px;
    max-width: 400px;
  }
  
  .cardContent {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .centerContent {
    text-align: center;
  }
  
  .actionButtons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  
  .followButton {
    border: none;
    background-color: #4e4dff;
    color: #fff;
    font-size: 1.2rem;
    border-radius: 35px;
    padding: 0.5rem 1rem;
    cursor: pointer;
  }
  
  .shareButton {
    border: none;
    background-color: #31263e;
    color: #fff;
    font-size: 1.2rem;
    border-radius: 35px;
    padding: 0.5rem 1rem;
    cursor: pointer;
  }
  
  .collabStatus {
    margin-top: 10px;
  }
  
  @media (max-width: 576px) {
    .profileHeader {
      text-align: center;
    }
  
    .profileDetails {
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
    }
  
    .profileInfo {
      margin-left: 10px;
      flex: 1;
    }
  
    .nameContainer {
      justify-content: flex-start;
      margin-top: 0;
    }
  
    .displayName {
      font-size: 16px;
    }
  
    .profileInfo p,
    .stats {
      text-align: left;
    }
  
    .avatar {
      width: 50px;
      height: 50px;
    }
  
    .collabStatus {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      margin-top: 10px;
    }
  
    .actionButtons {
      align-items: center;
      justify-content: flex-end;
      margin-top: 1.2rem;
      gap: 1.5rem;
    }
  
    .followButton,
    .shareButton,
    .collabButton {
      font-size: 0.8rem;
      padding: 1.2rem 1.5rem;
    }
  
    .collabButton {
      font-size: 0.8rem;
    }
  }
  
  .tableIcon {
    margin-right: 5px;
  }
  .tabStyle {
  display:"flex";
  align-items:"center" !important;
  justify-content:"center" !important;
  }

  .tabSpacing {
    margin-right: 20px;
}

.centerTabs {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

  /* here */
  
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 30px;
    margin-bottom: 0.5rem;
    gap: 1rem;
    background-color: #ececf3;
    border-radius: 20px;
    /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important; */
  }
  
  .logo {
    height: 40px;
  }
  
  .navLinks {
    display: flex;
    gap: 20px;
    margin-right: 2rem;
  }
  
  .navLinks a {
    text-decoration: none;
    color: #333;
    font-weight: 500;
  }
  
  .navLinks a:hover {
    color: #1890ff;
  }
  
  .retryButton {
    border-radius: 35px !important;
    background-color: #31263e !important;
    color: white !important;
  }
  
  .retryButton:hover {
    color: white !important;
    background-color: #4e4dff !important;
  }
  
  @media (max-width: 576px) {
    .navLinks {
      display: none;
    }
  
    .bottomNav {
      display: flex;
      justify-content: space-around;
      padding: 10px 0;
      background-color: #f5f5f5;
      position: fixed;
      width: 100%;
      bottom: 0;
      left: 0;
      box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1);
      z-index: 999;
    }
  
    .navItem {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #333;
      font-weight: 500;
    }
  
    .navItem span {
      font-size: 12px;
      margin-top: 4px;
    }
  
    .navItem:hover {
      color: #1890ff;
    }

  }
  
  .hidden {
    opacity: 0;
    transform: translateY(-100%);
    transition: opacity 0.3s, transform 0.3s;
  }
  

  .hideHeader {
    transform: translateY(-100%);
  }
  
  .main {
    margin-top: 10px;
    flex-grow: 1;
  
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none;
  }
  
  .mainHide {
    margin-top: 0px;
    flex-grow: 1;
  
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none;
  }
  
  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1rem;
    padding: 1rem;
    margin: 0 auto;
    margin-bottom: 300px;
  }
  
  .box {
    flex: 1 1 calc(33.333% - 20px);
    max-width: 100%;
  }
  
  .boxLast {
    flex: 1 1 calc(33.333% - 20px);
    margin-right: 0;
    background-color: #31263e;
    color: white;
    text-align: center;
    margin-top: 1rem;
    border-radius: 35px;
    padding: 1rem;
    font-size: 1rem;
    cursor: pointer;
  }
  
  
  .toggleButton {
    z-index: 100;
    background-color: #fff !important;
    border: none !important;
    border-radius: 5px;
  }
  
  .toggleButton:hover {
    background-color: #fff !important;
    color: #31263e !important;
    border: none !important;
  }
  
  .carousel {
    position: relative;
    width: 100%;
    height: 300px;
    overflow: hidden;
    max-width: 98vw;
    margin: auto !important;
  }
  
  @media (max-width: 850px) {
    .content {
      padding: 0 !important;
    }
    .cardWrapper {
      max-width: 100%;
    }
  }
  
  @media (max-width: 768px) {
    .box {
      flex: 1 1 calc(50% - 20px);
    }
  
    .content {
      padding: 0 !important;
    }
  
    .cardWrapper {
      max-width: 100%;
    }
  }
  
  @media (max-width: 480px) {
    .box {
      flex: 1 1 100%;
    }
  
    .content {
      padding: 0 !important;
    }
  }
  
  .socialLinkContainer {
    margin: auto;
  }
  
  .socialLinkWrapper {
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: start;
  }
  
  .socialLinkBox {
    height: auto;
    width: 350px;
    border-radius: 10px;
    border: 3px solid #897dda;
  }
  
  .socialLinkContent {
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;
    padding: 7px;
  }
  
  .socialLinkImage {
    width: 50px;
  }
  
  .socialLinkIcon {
    height: 30px;
    width: 30px;
  }
  
  .followerInfo {
    width: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .followerCount {
    font-size: 18px;
    font-weight: 800;
  }
  
  .linkButton {
    cursor: pointer;
    margin-left: 50px;
  }
  

  .customLayoutSideContainer {
    background-color: #f5f4fa !important;
    display: flex;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    background-origin: border-box;
    -ms-overflow-style: none;
    scrollbar-width: none;
    background-position: center;
    background-size: cover;
    width: 100%;
    align-items: start;
    justify-content: space-evenly;
    flex-direction: row;
  }