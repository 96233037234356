.shareholdersContainer {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.shareholderDetails {
  width: 90%;
}

.shareholderCard {
  background: #fff;
  border-radius: 13px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 300px;
  transition: box-shadow 0.3s ease-in-out;
}

.shareholderCard:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.cardContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.iconCol {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.eyeIcon {
  /* font-size: 24px; */
  color: #000;
  background-color: #fff;
  border-radius: 35px;
  padding: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.label {
  font-size: 14px;
  color: #7e8b9a;
  font-weight: 700;
  margin-bottom: 6px;
}

.infoText {
  font-size: 14px;
  color: #000;
}

.noShareholders {
  text-align: center;
  color: #888;
  font-size: 16px;
  margin-top: 20px;
}

.drawerContent .label {
  margin-bottom: 4px;
}

.drawerContent .infoText {
  margin-bottom: 12px;
}

.verificationItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 10px;
}

.verificationItem img {
  height: 30px;
  width: 30px;
  margin-bottom: 5px;
  object-fit: contain;
}

.verificationItem span {
  font-size: 12px;
  color: #333;
}

.saveBtn {
  background-color: #30263e !important;
  color: white !important;
  border-radius: 50px !important;
  padding: 0px 20px !important;
  font-size: 12px !important;
  width: auto !important;
  margin-bottom: 10px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 10px !important;
  border: none !important;
  outline: none !important;
  min-height: 30px !important;
  font-size: large !important;
}

.saveBtn:hover {
  background-color: #4e4dff !important;
}

.exitBTN {
  position: absolute;
  top: 5%;
  right: 5%;
  z-index: 100;
  cursor: pointer;
  border-radius: 50%;
  border: none !important;
  height: 50px;
  width: 50px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}

.exitText {
  font-size: 16px;
  font-weight: 600;
  color: #30263e;
}

.exitBTN:hover {
  transform: scale(1.1);
  box-shadow: 0px 0px 10px 0px rgba(255, 0, 0, 0.5);
}

.exitBTNModalExit {
  background-color: red;
  border-radius: 35px !important;
  border: none !important;
  color: white;
  height: 30px;
  width: 100px;
  font-size: 16px;
  font-weight: 600;
  margin-right: 10px;
}

.exitBTNModalExit:hover {
  transform: scale(1.1);
  background-color: red !important;
  color: white !important;
  box-shadow: 0px 0px 10px 0px rgba(255, 0, 0, 0.5);
}

.exitBTNModalStay {
  background-color: #30263e;
  border-radius: 35px !important;
  border: none !important;
  color: white;
  height: 30px;
  width: 100px;
  font-size: 16px;
  font-weight: 600;
  margin-right: 10px;
}

.exitBTNModalStay:hover {
  transform: scale(1.1);
  background-color: blue !important;
  color: white !important;
  box-shadow: 0px 0px 10px 0px rgba(255, 0, 0, 0.5);
}

.emptyContainer {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: space-between !important;
  height: 100% !important;
  width: 100% !important;
  margin-top: 10px !important;
  gap: 1rem !important;
}

.addBtnTop {
  margin-left: auto !important;
}
