body {
  font-family: "Arial", sans-serif;
  margin: 20px;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
}

.card {
  width: 300px;
  margin: 10px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.expanded-content {
  margin-top: 10px;
}

.ratingIcon {
    margin-left: 50px;
  background:black;
  width: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
  color:white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 5px;
  border-radius:  35px;
  border: 2px solid white;
  font-weight: bold;
}

.expand-btn {
  margin-top: 10px;
}
