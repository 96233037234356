
.tableContainer {
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 24px;
  background-color: none !important;
  background: none !important;
  box-shadow: none !important;
  border: none !important;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.customHeader {
  background-color: white;
  margin-bottom: 24px;
  border-radius: 15px;
  padding-left: 15px;
  padding-right: 15px;
  width: 95%;
}

.tableBodyHeader {
  padding-top: 1rem;
}

.table {
  width: 100%;
  border-collapse: collapse;
  background-color: white !important;
}

.tableHeader {
  padding: 15px;

  height: 20px !important;
  border-radius: 5px !important;
}
.tableHeader tr,
.tableHeader th {
  height: 20px !important;
  border-radius: 5px !important;
  border: none !important;
}

.tableBody {
  background-color: #ffffff;
  padding: 15px;

  height: 20px !important;
  border-radius: 5px !important;
}

.tableBody tr td {
  border-bottom: 1px solid #ddd;
}

.tableBody tr:last-child td {
  border-bottom: none;
}


.iconButton {
  border: none;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
  cursor: pointer;
  opacity: 80%;
  font-size: 24px;
}

.collabButton {
  background-color: #ffffff;
  cursor: pointer;
  font-size: 24px;
  border: none;
  text-align: center;
}

.collabText {

  font-size: 16px;
}

.tbCell {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.actionDiv {
  display: flex;
  flex-direction: row;
  /* align-items: center;
  justify-content: center; */
  
}

.activeDiv {
  background: #F1F1FF;
  border-radius: 500px;
  width: 60px;
  color: #4E4DFF;
  text-align: center;
}

.draftedDiv {
  background: #FFF8EB;
  border-radius: 500px;
  color: #FF7A00;
  width: 68px;
  text-align: center;
}

.inactiveDiv {
  background: #FFF2F2;
  border-radius: 500px;
  width: 70px;
  color: #FF5757;
  text-align: center;
}

.statusDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.productImage {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}

.prodNameDiv {
  display: flex;
  justify-content: left;
  align-items: center;
}

.noCollabDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lowStock {
  background: #FFF2F2;
  border-radius: 40px;
  font-size: 10px;
  font-family: Google Sans;
  color: #FF5757;
  text-align: center;
  min-height: 20px;
  min-width: 36px;
  /* padding: 6.5px, 8px, 6.5px, 8px; */
  margin-left: 10px;
}

.soldOutText {
  color: #FD7E58;
}