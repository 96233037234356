.customLayoutBG {
  background-color: #f5f4fa !important;
}

.parentContentdiv {
  display: flex;
  gap: 1rem;
}

.breadcrumbItem:hover {
  cursor: pointer;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.serviceHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.actionsContainerMain {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  margin-right: 1rem;
}

.gradientText {
  background-image: linear-gradient(114.95deg, #8800ff 0.76%, #00b3ff 47.23%);
  -webkit-text-fill-color: transparent;
}

.buttonFilled {
  background-color: #4e4dff;
  color: white;
  border: none;
  border-radius: 35px;
  margin-right: 5px;
  cursor: pointer;
}

.buttonTransparent {
  border: 1px solid #cecbd4;
  color: #30263e;
  background-color: transparent;
  background: transparent;
  font-weight: bold;
  border-radius: 35px;
}

.profilePicture {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: -10px;
  border: 2px solid white;
  margin-right: 1rem;
}

.profilePictureCard {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: -10px;
    border: 2px solid white;
    box-shadow: 0px 10px 30px 0px #30263e0f;
}

.profilePicturesContainer {
  display: flex;
}

.dropdownDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.placeholderText {
  color: #30263e;
  font-weight: bold;
}

.dropdownMain {
  background-color: #ffffff;
  border-radius: 35px;
}

.parentContentdiv {
  display: flex;
  gap: 1rem;
}

.cardContainer {
  margin-top: 20px;
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  min-width: 650;
}

.tableContainer {
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 24px;
    background-color: none !important;
    background: none !important;
    box-shadow: none !important;
    border: none !important;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.customHeader {
    background-color: white;
    margin-bottom: 24px;
    border-radius: 15px;
    padding-left: 15px;
    padding-right: 15px;
    width: 95%;
}

.tableBodyHeader {
    padding-top: 1rem;
}

.table {
    width: 100%;
    border-collapse: collapse;
    background-color: white !important;
}

.tableHeader {
    padding: 15px;

    height: 20px !important;
    border-radius: 5px !important;
}

.tableHeader tr,
.tableHeader th {
    height: 20px !important;
    border-radius: 5px !important;
    border: none !important;
}

.tableBody {
    background-color: #ffffff;
    padding: 15px;

    height: 20px !important;
    border-radius: 5px !important;
}

.tableBody tr td {
    border-bottom: 1px solid #ddd;
}


.cardBody {
    background-color: #ffffff;
    padding: 15px;
    height: 80px !important;
    border-radius: 5px !important;
}

.cardBody_freelancer {
  background-color: #ffffff;
  padding: 15px;
  height: 55px !important;
  border-radius: 5px !important;
}

.customHeader {
  background-color: white;
  margin-bottom: 24px;
  border-radius: 15px;
  padding-left: 15px;
  padding-right: 15px;
  width: 95%;
}

.tableBodyHeader {
  padding-top: 1rem;
}

.table {
  width: 100%;
  border-collapse: collapse;
  background-color: white !important;
}

.tableHeader {
  padding: 15px;

  height: 20px !important;
  border-radius: 5px !important;
}
.tableHeader tr,
.tableHeader th {
  height: 20px !important;
  border-radius: 5px !important;
  border: none !important;
}

.tableBody {
  background-color: #ffffff;
  padding: 15px;

  height: 20px !important;
  border-radius: 5px !important;
}

.tableBody tr td {
  border-bottom: 1px solid #ddd;
}


.separator {
  pointer-events: none !important;
  cursor: default !important;
  margin-top: 25px;
  color: grey;
  opacity: 50%;
}

.profilePictureDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: aqua;
  border: 2px;
}

.cardHeaders {
  margin-top: 15px;
  font-size: 18px;
  font-weight: bold;
}

.cardHeaders1 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 2px;
}

.cardHeaderDetails {
  font-size: 10px;
  font-weight: 400;
  margin-top: 5px;
  color: #30263e;
  opacity: 50%;
}

.cardHeaderDivMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}

.prodNameDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.displayColor {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
    box-shadow: 0px 10px 30px 0px #30263e0f;
    border: 2px solid #d8d8d8;
}

.descriptionContainer:hover {
    overflow: visible;
    white-space: normal;
    max-height: none;
}

.displayColorInfo {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
    box-shadow: 0px 10px 30px 0px #30263e0f;
    border: 2px solid #d8d8d8;
}

.toolTipRow {
    display: flex;
    align-items: center;
    font-family: Google Sans;
    font-size: 14px;
    font-weight: 500;
    padding: 3px;
}

.inventoryCount {
    width: 32px;
    height: 24px;
    display: flex;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 8px 24px 0px #422f9b1a;
    background-color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    color: #4e4dff;
    letter-spacing: -0.01em;
}

.toolTipMain {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px;
}

.toolTipDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center
}

.infoIcon {
    margin-top: 7px;
    margin-left: 5px
}

.colorChip {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.buttonDark {
  color: white;
  font-weight: bold;
  background-color: #30263e;
  border-radius: 20px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  border: none !important;
}


.addButton {
  background-color: #4e4dff;
  color: white;
  border: none;
  border-radius: 35px;
  margin-right: 5px;
  cursor: pointer;
  min-width: 80px;
}
.addButton:hover,
.addButton:active {
  background-color: #ffffff !important;
  color: #4e4dff !important;
  border: 1px solid #4e4dff;
  border-radius: 35px;
  margin-right: 5px;
  cursor: pointer;
}

.eye {
  color: #97929e;
  border: none !important;
  box-shadow: none !important;
}

.countingSpan {
  background: white;
  padding: 0.1rem 0.5rem;
  border-radius: 35px;
  color: #30263e;
  font-weight: bold;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin-left: 0.2rem;
}

.contractDiv {
  border-radius: 10px;
  padding: 10px;
  margin-right: 10px;
  background: #f2f0ff;
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  height: 130px ;
  justify-content: space-around;
  margin-bottom: 10px;
}