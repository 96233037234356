
.cancelIcon {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 16px;
}

.title {
  text-align: center;
  margin-bottom: 24px;
}


.formItem {
  margin-bottom: 24px;
  border: 4px solid #f5f4f5 !important;
  border-radius: 10px !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}

.input,
input:hover,
input:focus,
input:active {
  border-radius: 18px !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.footer {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}
.footerDiv {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}
.footer1 {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.footerReject {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}
.cancelButton {
  /* Styles for the policy/cancel button */

  border: none !important;
  box-shadow: none !important;
}

.cancelText {
  font-size: 16px;
  font-weight: bold;
  text-decoration: underline !important;
}

.submitButton {
  /* Styles for the submit button */
  background-color: #4e4dff;
  font-weight: bold;
  border-radius: 35px;
  height: 40px;
  min-width: 150px;
  font-size: 16px;
}

.submitButton:hover,
.submitButton:focus,
.submitButton:active {
  background-color: #30263e !important;
  border-radius: 35px;
  height: 40px;
  min-width: 160px;
  font-size: 16px;
}

.closeIcon {
  height: 50px;
  width: 50px;
}
.closeIcon {
  position: absolute;
  top: 15px;
  right: 20px;
  border: none;
  background: none;
  padding: 0;
}

/* ... other styles ... */

.policyButton {
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: #30263e;
  text-decoration: underline;
  margin-right: 8px;

  border: none !important;
  box-shadow: none !important;
  border-radius: 35px !important;
}

.formItem {
  margin-bottom: 24px;
}

.input {
  border-radius: 4px;
}

/* PopupForm.module.css */

.policyDetails {
  background-color: #f8f8f8;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.policyHeader {
  text-align: center;
  font-weight: 600;
  color: #333;
  margin-bottom: 16px;
}

.policyList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.policyList li {
  margin-bottom: 10px;
  padding-left: 16px;
  text-align: left;
  line-height: 1.6;
  position: relative;
}

.policyList li::before {
  content: "";
  width: 6px;
  height: 6px;
  background-color: #007bff;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.formInput,
.formSelect,
.formTextArea,
.formInput:hover,
.formInput:active,
.formInput:focus,
.formInput:visited,
.formSelect,
.formSelect:hover,
.formSelect:active,
.formSelect:focus,
.formSelect:visited,
.formTextArea,
.formTextArea:hover,
.formTextArea:active,
.formTextArea:focus,
.formTextArea:visited {
  width: 100%;
  background-color: white !important;
  border-radius: 35px !important;
  height: 40px !important;
  font-size: 14px !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  border: none !important;
  display: flex !important;
  align-items: center !important;
  /* justify-content: center !important; */
  padding: 0rem 1rem !important;
  border: 3px solid #f5f4f5 !important;
}

.textArea {
  width: 100%;
  background-color: white !important;
  height:80px !important;
  font-size: 14px !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  border: none !important;
  display: flex !important;
  align-items: center !important;
  /* justify-content: center !important; */
  padding: 0rem 1rem !important;
  border: 3px solid #f5f4f5 !important;

}

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.rejectButton {
  color: white;
  font-weight: bold;
  background-color: #30263e;
  border-radius: 20px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  border: none !important;
}


.servicesContainer{
  margin-top: 30px;
  border: none !important;
}

.chatContainer {
  border-radius: 20px;
  width: 100%;
  height: 50%;
  border: none !important;
  overflow: hidden;
  margin-top: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 0px 5px 5px !important;
}

.chatContainer_error {
  border-radius: 20px;
  width: 100%;
  height: 50%;
  border: none !important;
  overflow: hidden;
  margin-top: 10px;
  text-align: center;
}

.closeButton {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 5px;
  align-items: flex-end;
}

.closeChatBtn {
  border: none;
  background-color: transparent;
  color: #4e4dff;
}