.addButton {
  margin-top: 30px;
  margin-right: 0px;
  width: 150px;
  height: 35px;
  border: none;
  border-radius: 40px;
  /* border-color: #000000; */
  background-color: #ccc;
  color: white;
  padding: 19.5px, 50px, 19.5px, 50px;
  font-style: Bold;
  font-size: 18px;
  cursor:not-allowed;
}
.ccButton {
  border: none;
  width: 150px;
  height: 30px;
  border-radius: 40px;
  border-color: #1677ff;
  background-color: #fff;
  /* color: white; */
  padding: 19.5px, 50px, 19.5px, 50px;
  font-style: Bold;
  font-size: 18px;
  cursor: pointer;
  
}

.NoDataFound {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
  height: 100%;
}

.selectedPaymentType {
  background-color: #4e4dff !important;
  color: #fff !important;
}
.dcButton {
  border: none;
  width: 150px;
  height: 30px;
  border-radius: 40px;
  border-color: white;
  background-color: white;
  color: black;
  padding: 19.5px, 50px, 19.5px, 50px;
  font-style: Bold;
  font-size: 18px;
  cursor: pointer;
}
.bpButton {
  border: none;
  width: 150px;
  height: 30px;
  border-radius: 40px;
  border-color: white;
  background-color: white;
  color: black;
  padding: 19.5px, 50px, 19.5px, 50px;
  font-style: Bold;
  font-size: 18px;
  cursor: pointer;
}
.payCard {
  /* height: auto;
    width:auto; */
  border-radius: 20px;
  box-shadow: 2px #1677ff;
  background-color: white;
  width: 100%;
  margin-top: 50px;
  height: 200px;
  /* height */
  /* background: url("../../../assests/CardBackground.png"),
    linear-gradient(to right, #6db3f2, #6db3f2); */
  background-image: linear-gradient(
      to right,
      rgba(109, 179, 242, 0.6),
      rgba(109, 179, 242, 0.6)
    ),
    url("../../../assests/CardBackground.png");
  background-color: #1677ff;
}
.cardImg {
  height: 30px;
  width: 40px;
  margin-top: 15px;
  margin-left: 20px;
  margin-bottom: 40px;
}
.cardContent {
  margin-top: 15px;
  margin-left: 20px;
  font-size: 16px;
  font-weight: bold;
  word-spacing: 5px;
  letter-spacing: 1px;
}
