.appointment-card {
  font-family: "Arial", sans-serif;
  border-radius: 25px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  overflow: hidden;
  max-width: 100%;
  background-color: #f2f0ff;
}

.card-header {
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: #e3dfff;
  border-radius: 0px 0px 25px 25px;
}

.card-header-user {
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: #f2f0ff;
  border-radius: 0px 0px 25px 25px;
}

.service-icon {
  border-radius: 50%;
  margin-right: 16px;
  width: 50px; /* Adjust as per your icon size */
  height: 50px; /* Adjust as per your icon size */
}

.card-header h2 {
  margin: 0;
  font-size: 18px;
  color: #333;
}

.card-header p {
  margin: 4px 0;
  font-size: 16px;
  color: #666;
}

.card-body {
  padding: 16px;
  background-color: #f2f0ff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-body p {
  margin: 4px 0;
  font-size: 16px;
  color: #333;
}

.name-container-with-chat {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
