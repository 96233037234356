.personalInfoOuterCotainer {
  width: 80%;
  /* margin: 10% auto; */
}

.personalInfoHeadingCotainer {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.personalInfoEditBtnContainer {
  width: 50px;
  background: #fff;
  border-radius: 50px;
  height: 50px;
  display: flex;
  /* font-size: 49px; */
  align-items: center;
  cursor: pointer;
  justify-content: center;
}

.personalInfoLabelCotainer {
  font-size: 19px;
  width: 100%;
  display: flex;
  align-items: start;
  color: grey;
  justify-content: start;
  margin: 20px 0px;
}
.personalInfoAnswerCotainer {
  text-align: justify;
  justify-content: start;
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin: 20px 0px;
  word-wrap: break-word; /* Wrap long words */
  word-break: break-all; /* Break long words */
  overflow: hidden; /* Hide overflow */
  white-space: normal; /* Allow text to wrap */
}
