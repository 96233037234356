.rectangleParent {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 35px;
  /* border: 1px solid red; */
}
.ant-modal-content {
  border-radius: 15px !important;
  /* border: 1px solid red !important; */
  /* background-color: transparent !important; */
  background: linear-gradient(151deg, #ecd8ff, #d8f3ff 60.52%) !important;

  /* box-shadow: none !important; */
}

.ant-modal-close {
  background-color: white !important;
  /* border: 1px solid red !important; */
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important; */
  padding: 5px !important;
  height: 50px !important;
  width: 50px !important;
  border-radius: 50% !important;
  display: none !important;
}
.component6Icon {
  margin: 20px auto;
}

.awaitingVerificationForContainer {
  position: relative;
  border-radius: 35px;
}

.awaitingVerification {
  margin: 0;
}

.groupItem {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
}

.yourStoreInformation1 {
}

.button {
  margin-top: 20px;
  cursor: pointer;
  background-color: #4e4dff;
  color: #fff;
  font-weight: bold;
  width: 200px;
  height: 50px;
  border-radius: 35px;
}
.button:hover {
  color: white !important;
  background-color: #30263e !important;
  border: none !important;
}
.button1:hover {
  /* Your button styles */
  color: white;
}
