.renderPhoneNumberInputBG {
  background: #fff !important;
  width: 100px;
  height: 40px;
  border-radius: 90px;
}

.renderPhoneNumberInputTAG {
  border-radius: 120px;
}

.renderPhoneNumberInputOption {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
