.collapse_button {
  position: absolute;
  top: 16px;
  right: -16px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}

.sidebar_icon {
  transition: transform 0.3s ease;
  width: 40px;
  height: 40px;
}

.sidebar_icon.collapsed {
  transform: rotate(180deg);
}

.logo {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;

  margin: auto;
  margin-top: 1rem;
}

.logoImage {
  width: 90%;
}
.logoImageOpen {
  width: 90%;
}

.logoImageClosed {
  width: 50%;
}

.menu li {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  color: #7e8b9a !important;
  font-weight: bold !important;
}

.menuItemLi {
  background: linear-gradient(107deg, #80f 0.56%, #00b2ff 75.66%) !important;
}

.menuItemLabel {
  color: #7e8b9a;
}

.menuItemLabelSelected {
  color: white;
}

.menuItem {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
