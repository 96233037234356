.cardCategory {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  border-radius: 30px;
  background-color: #fff;
  position: absolute;
  bottom: -15px;
  left: 15px;
  padding: 8px 15px;
}

.cardRatingServiceCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background-color: #30263e;
  position: absolute;
  top: 10px;
  left: 10px;
  padding-top: 20px;
  padding-left: -10px;
}

.cardActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 10px 15px;
  padding-left: 10px;
  gap: 5px;
}

.serviceCard {
  width: 100%;
  text-decoration: none;
  color: gray;
  min-width: 300px;
  max-width: 400px;
  position: relative;
  gap: 10px;
}

.serviceInfo {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 7px;
}

.cardPricing {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  margin: 5px;
}

.priceVal {
  color: #30263e;
  font-weight: 500;
  margin: 0;
}

.serviceCapsule {
  position: absolute;
  top: 15px;
  /* Adjust based on your layout */
  right: 10px;
  /* Adjust based on your layout */
  background: linear-gradient(45deg, rgb(52, 152, 219), rgb(187, 134, 252));
  color: white;
  /* Text color */
  padding: 5px 10px;
  /* Adjust padding to control the capsule size */
  border-radius: 15px;
  /* Gives the capsule its rounded corners */
  font-size: 16px;
  /* Adjust the font size as needed */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  /* Optional: Adds a subtle shadow for depth */
  /* Ensures the capsule is above other content */
  font-weight: bold;
  border: 0.03rem solid white;
}

.serviceCapsuleAwait {
  position: absolute;
  top: 15px;
  /* Adjust based on your layout */
  left: 10px;
  /* Adjust based on your layout */
  background: linear-gradient(45deg, rgb(52, 152, 219), rgb(187, 134, 252));
  color: white;
  /* Text color */
  padding: 5px 10px;
  /* Adjust padding to control the capsule size */
  border-radius: 15px;
  /* Gives the capsule its rounded corners */
  font-size: 16px;
  /* Adjust the font size as needed */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  /* Optional: Adds a subtle shadow for depth */
  z-index: 5;
  /* Ensures the capsule is above other content */
  font-weight: bold;
  border: 0.03rem solid white;
}

.serviceCapsuleActive {
  position: absolute;
  top: 15px;
  /* Adjust based on your layout */
  left: 10px;
  /* Adjust based on your layout */
  /* make it in active color red gradient */
  /* background: linear-gradient(45deg, rgb(219, 55, 52), rgb(187, 134, 252));
   */
  background: rgba(253, 29, 29, 1);

  color: white;
  /* Text color */
  padding: 5px 10px;
  /* Adjust padding to control the capsule size */
  border-radius: 15px;
  /* Gives the capsule its rounded corners */
  font-size: 16px;
  /* Adjust the font size as needed */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  /* Optional: Adds a subtle shadow for depth */
  z-index: 5;
  /* Ensures the capsule is above other content */
  font-weight: bold;
  border: 0.03rem solid white;
}