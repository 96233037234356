.buttonWrapper {
  position: fixed !important;
  left: 17% !important;
  top: 0% !important;
  transform: translateY(150%) !important;
  z-index: 1000 !important;
  text-align: center !important;
}

.floatingButton {
  background-color: #4e4dff !important;
  color: #ffffff !important;
  border-color: #4e4dff !important;
  font-weight: bold !important;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15) !important;
  padding: 12px 24px !important;
  border-radius: 50px !important;
}

.floatingButton:hover {
  background-color: #30263e !important;
  border-color: #30263e !important;
}

.label {
  position: absolute !important;
  top: -10px !important;
  right: -10px !important;
  background-color: #ff4d4f !important;
  color: #fff !important;
  font-size: 12px !important;
  font-weight: bold !important;
  padding: 2px 5px !important;
  border-radius: 10px !important;
  animation: blink 1.5s infinite !important;
}

.drawerContent {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 100% !important;
}

.imagePreview {
  max-width: 100% !important;
  max-height: 100% !important;
  object-fit: contain !important;
}

.pdfPreview {
  width: 100% !important;
  height: 100% !important;
  border: none !important;
}

.drawerClosedLabel {
  position: absolute !important;
  top: -10px !important;
  left: -150px !important;
  background-color: #ff4d4f !important;
  color: #ffffff !important;
  font-size: 14px !important;
  font-weight: bold !important;
  padding: 5px 10px !important;
  border-radius: 10px !important;
  animation: blink 1.5s infinite !important;
  transform: rotate(-45deg) !important;
}

.drawerClosedLabelText {
  display: block !important;
}

.drawer {
  position: relative;
}

.drawerToggleButton {
  position: fixed !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  transition: left 0.3s ease !important;
  z-index: 1000 !important;
  width: 40px !important;
  height: 50px !important;
  border-radius: 0 50px 50px 0 !important;
  background-color: #4e4dff !important;
  color: #ffffff !important;
  border: none !important;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15) !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.drawerToggleButton:hover {
  background-color: #30263e !important;
}

.drawerOpen {
  left: 500px !important;
}

.drawerClosed {
  left: 0 !important;
}

@keyframes heartbeat {
  0%,
  100% {
    transform: scale(1);
  }
  20%,
  80% {
    transform: scale(1.3);
  }
  40%,
  60% {
    transform: scale(1.5);
  }
}

.iconAnimation {
  /* animation: heartbeat 1s ease-in-out infinite; */
}

.notificationDot {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
  z-index: 1;
  animation: heartbeat 1s ease-in-out infinite;
}

@keyframes blink {
  50% {
    opacity: 0.5;
  }
}
