/* Default styles */

.setFlexCol {
  display: flex;
  flex-direction: column;
}

.setFlexRow {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.extrasCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.setTextStart {
  display: flex;
  flex-direction: column;
  align-items: start;
}

/* Loading indicator styles */

.loading-indicator:before {
  content: "";
  background: #000000b4;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

.loading-indicator:after {
  content: "";
  position: fixed;
  width: 100%;
  top: 50%;
  left: 0;
  z-index: 1001;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
}

#loading-indicator {
  background: rgba(255, 255, 255, 0.539);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 10000;
  display: none;
}

#tentacle {
  stroke: black;
  stroke-width: 0.5;
  fill: none;
  stroke-dasharray: 1000;
  stroke-dashoffset: 900;
  animation: draw-erase 5s linear infinite;
  animation-delay: 0ms;
  animation-duration: 2s;
}

.cc__icon {
  border: 1px solid red !important;
}
.custom-modal-content-for-stories {
  /* border: 1px solid red !important; */
  z-index: 99999 !important;
}

.error {
  color: red;
}

@keyframes draw-erase {
  0%,
  100% {
    stroke-dashoffset: 800;
  }
  1% {
    stroke-dashoffset: 810;
  }
  2% {
    stroke-dashoffset: 820;
  }
  3% {
    stroke-dashoffset: 830;
  }
  4% {
    stroke-dashoffset: 840;
  }
  5% {
    stroke-dashoffset: 850;
  }
  6% {
    stroke-dashoffset: 860;
  }
  7% {
    stroke-dashoffset: 870;
  }
  8% {
    stroke-dashoffset: 880;
  }
  9% {
    stroke-dashoffset: 890;
  }
  10% {
    stroke-dashoffset: 900;
  }
  11% {
    stroke-dashoffset: 910;
  }
  12% {
    stroke-dashoffset: 920;
  }
  13% {
    stroke-dashoffset: 930;
  }
  14% {
    stroke-dashoffset: 940;
  }
  15% {
    stroke-dashoffset: 950;
  }
  16% {
    stroke-dashoffset: 960;
  }
  17% {
    stroke-dashoffset: 970;
  }
  18% {
    stroke-dashoffset: 980;
  }
  19% {
    stroke-dashoffset: 990;
  }
  20% {
    stroke-dashoffset: 1000;
  } /*
  21% {
    stroke-dashoffset: 790;
  }
  22% {
    stroke-dashoffset: 780;
  }
  23% {
    stroke-dashoffset: 770;
  }
  24% {
    stroke-dashoffset: 760;
  }
  25% {
    stroke-dashoffset: 750;
  }
  26% {
    stroke-dashoffset: 740;
  }
  27% {
    stroke-dashoffset: 730;
  }
  28% {
    stroke-dashoffset: 720;
  }
  29% {
    stroke-dashoffset: 710;
  }
  30% {
    stroke-dashoffset: 700;
  }
  31% {
    stroke-dashoffset: 690;
  }
  32% {
    stroke-dashoffset: 680;
  }
  33% {
    stroke-dashoffset: 670;
  }
  34% {
    stroke-dashoffset: 660;
  }
  35% {
    stroke-dashoffset: 650;
  }
  36% {
    stroke-dashoffset: 640;
  }
  37% {
    stroke-dashoffset: 630;
  }
  38% {
    stroke-dashoffset: 620;
  }
  39% {
    stroke-dashoffset: 610;
  }
  40% {
    stroke-dashoffset: 600;
  }
  41% {
    stroke-dashoffset: 590;
  }
  42% {
    stroke-dashoffset: 580;
  }
  43% {
    stroke-dashoffset: 570;
  }
  44% {
    stroke-dashoffset: 560;
  }
  45% {
    stroke-dashoffset: 550;
  }
  46% {
    stroke-dashoffset: 540;
  }
  47% {
    stroke-dashoffset: 530;
  }
  48% {
    stroke-dashoffset: 520;
  }
  49% {
    stroke-dashoffset: 510;
  }
  50% {
    stroke-dashoffset: 500;
  }
  51% {
    stroke-dashoffset: 490;
  }
  52% {
    stroke-dashoffset: 480;
  }
  53% {
    stroke-dashoffset: 470;
  }
  54% {
    stroke-dashoffset: 460;
  }
  55% {
    stroke-dashoffset: 450;
  }
  56% {
    stroke-dashoffset: 440;
  }
  57% {
    stroke-dashoffset: 430;
  }
  58% {
    stroke-dashoffset: 420;
  }
  59% {
    stroke-dashoffset: 410;
  }
  60% {
    stroke-dashoffset: 400;
  }
  61% {
    stroke-dashoffset: 390;
  }
  62% {
    stroke-dashoffset: 380;
  }
  63% {
    stroke-dashoffset: 370;
  }
  64% {
    stroke-dashoffset: 360;
  }
  65% {
    stroke-dashoffset: 350;
  }
  66% {
    stroke-dashoffset: 340;
  }
  67% {
    stroke-dashoffset: 330;
  }
  68% {
    stroke-dashoffset: 320;
  }
  69% {
    stroke-dashoffset: 310;
  }
  70% {
    stroke-dashoffset: 300;
  }
  71% {
    stroke-dashoffset: 290;
  }
  72% {
    stroke-dashoffset: 280;
  }
  73% {
    stroke-dashoffset: 270;
  }
  74% {
    stroke-dashoffset: 260;
  }
  75% {
    stroke-dashoffset: 250;
  }
  76% {
    stroke-dashoffset: 240;
  }
  77% {
    stroke-dashoffset: 230;
  }
  78% {
    stroke-dashoffset: 220;
  }
  79% {
    stroke-dashoffset: 210;
  }
  80% {
    stroke-dashoffset: 200;
  }
  81% {
    stroke-dashoffset: 190;
  }
  82% {
    stroke-dashoffset: 180;
  }
  83% {
    stroke-dashoffset: 170;
  }
  84% {
    stroke-dashoffset: 160;
  }
  85% {
    stroke-dashoffset: 150;
  }
  86% {
    stroke-dashoffset: 140;
  }
  87% {
    stroke-dashoffset: 130;
  }
  88% {
    stroke-dashoffset: 120;
  }
  89% {
    stroke-dashoffset: 110;
  }
  90% {
    stroke-dashoffset: 100;
  }
  91% {
    stroke-dashoffset: 90;
  }
  92% {
    stroke-dashoffset: 80;
  }
  93% {
    stroke-dashoffset: 70;
  }
  94% {
    stroke-dashoffset: 60;
  }
  95% {
    stroke-dashoffset: 50;
  }
  96% {
    stroke-dashoffset: 40;
  }
  97% {
    stroke-dashoffset: 30;
  }
  98% {
    stroke-dashoffset: 20;
  }
  99% {
    stroke-dashoffset: 10;
  }
  100% {
    stroke-dashoffset: -1000;
  } */
}

/* Button and Reticule styles */

.buttonBG {
  background: rgba(78, 77, 255, 1) !important;
}

.buttonBlack {
  background: #30263e !important;
}

.blackOutline {
  color: #30263e;
  border-radius: 30px;
  border: 1px solid #30263e;
}

.blackOutline:hover {
  background: #30263e;
  color: #fff !important;
  border: 1px solid #30263e !important;
}

.disabledBtn {
  background: rgba(78, 77, 255, 0.6) !important;
  color: #eee !important;
}

.rdp-reticule {
  border-color: rgba(78, 77, 255, 1) !important;
}

/* Text Overlay styles */

.rdp-text-overlay div {
  font-family: "Ubuntu", sans-serif !important;
  font-style: normal !important;
  display: none !important;
}

/* Image Gallery styles */

.image-gallery-image,
.image-gallery-image img {
  border-radius: 10px !important;
}

.image-gallery-thumbnail,
.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus,
.image-gallery-thumbnail:hover {
  border: none !important;
  border-radius: 20px;
  width: 90px !important;
  height: 90px !important;
  cursor: pointer !important;
}

.image-gallery-thumbnail img {
  /* gray effect */
  filter: grayscale(1);
}

.image-gallery-thumbnail.active img {
  filter: grayscale(0);
}
.image-gallery-thumbnail-inner {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.image-gallery-thumbnails-container {
  height: auto !important;
}

.image-gallery-thumbnail.active img {
}

.image-gallery-thumbnail.active > .image-gallery-thumbnail-inner {
}

.image-gallery-image {
  max-height: 400px;
}

.image-gallery-swipe {
  border-radius: 15px !important;
  padding: 5px;
  background: linear-gradient(151deg, #ecd8ff, #d8f3ff 60.52%);
}

.image-gallery-slides,
.image-gallery-image {
  border-radius: 10px !important;
  max-height: 250px !important;
}

.image-gallery-slides {
}

.image-gallery-thumbnail.active > .image-gallery-thumbnail-inner::after {
  content: "";
  display: block;
  width: 40%;
  height: 10px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 6'><path d='M3 3H23' stroke='url(%23paint0_linear_2470_73099)' stroke-width='5' stroke-linecap='round'/><defs><linearGradient id='paint0_linear_2470_73099' x1='3' y1='3.02392' x2='3.68623' y2='7.1851' gradientUnits='userSpaceOnUse'><stop stop-color='%238800FF'/><stop offset='0.755208' stop-color='%2300B3FF'/></linearGradient></defs></svg>");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  bottom: -10%;
  left: 0;
  pointer-events: none;
  bottom: -10%; /* This positions it outside the bottom edge, adjust as needed */
  left: 50%; /* Start at the horizontal center */
  transform: translateX(-50%); /* Shift it back to truly center it */
  pointer-events: none;
}

.image-gallery-thumbnail img {
  border-radius: 10px;
  width: 75px !important;
  height: 75px !important;
  margin-bottom: 10px;
}

.image-gallery-icon:hover {
  color: #fff !important;
}

.floating-home-button {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: transparent;
  color: #31263e;
  border: none;

  /* width: 50px;
  height: 50px; */
  cursor: pointer;
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  transition: background-color 0.3s, transform 0.3s;
  /* border-bottom: 2px solid currentColor; */
  display: flex;
  justify-content: center;
  align-items: flex-end;
  /* font-weight: bold; */
}
.floating-home-button-icon {
  width: 50px;
}

/* Responsive styles */

@media screen and (max-width: 600px) {
  /* Add responsive styles here */
  .setFlexRow {
    flex-direction: column;
  }

  .setTextStart {
    align-items: center;
  }

  .floating-home-button {
    right : -28px;
    font-size: 12px;
  }
}
/* @media screen and (max-width: 1370px) {
  .floating-home-button {
    display: none;
  }
} */

/* Add more media queries as needed for other screen sizes */

.cc-message-list {
  max-height: 80vh !important;
}

.ant-drawer-header {
  border: none !important;
}
.ant-input-disabled {
  background-color: none !important;
}

.ant-input[disabled],
.ant-select[disabled],
.ant-select-disabled,
.ant-select-selector {
  background-color: transparent !important;
}

/* .ant-modal-title {
  background: linear-gradient(151deg, #ecd8ff, #d8f3ff 60.52%) !important;
} */

html {
  scrollbar-width: none !important;
}
html::-webkit-scrollbar {
  display: none !important;
}

body,
.rc-virtual-list {
  scrollbar-width: none !important;
  margin-right: -14px !important;
  overflow-y: scroll;
  margin-bottom: -14px !important;
  overflow-x: scroll;
}

.rc-virtual-list {
  padding: 0.5rem !important;
}

@namespace url("http://www.mozilla.org/keymaster/gatekeeper/there.is.only.xul"); /* only needed once */

:-moz-any(body) browser {
  margin-right: -14px !important;
  overflow-y: scroll;
  margin-bottom: -14px !important;
  overflow-x: scroll;
}

.ant-image .ant-image-mask {
  /* border-radius: 15px !important; */
}

.ant-space-horizontal {
  align-items: center !important;
  border-radius: 15px !important;
}

/*  make changes in this file who tf have done this */

.ant-space-horizontal .ant-space-item {
  border-radius: 15px !important;
  width: 100% !important;
}

.ant-space-horizontal .ant-space-item > div {
  border-radius: 15px !important;
  display: flex;
  flex-direction: column-reverse;
  padding: 0.05rem;

  gap: 1rem;
}
.ant-select-item-option,
.ant-select-item-option-active {
  border-radius: 10px 0px 0px 10px !important;
}

.breadcrumbItem:hover {
  cursor: pointer;
}

.ant-drawer-mask {
  background: rgba(0, 0, 0, 0.1) !important;
}
.ant-drawer-content-wrapper {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
}

.ant-input-number-input-wrap {
  width: 100% !important;
}

.cc__button,
.cc__icon {
  display: none !important;
}

.rc-virtual-list-holder-inner,
.ant-space-horizontal {
  padding: 0% !important;
  min-width: max-content !important;
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  justify-items: center;
}

.rc-virtual-list-holder-inner div {
  min-width: 140px !important;
  width: 100% !important;
  display: flex !important;
  /* justify-content: center !important; */
  align-items: center !important;
  /* justify-items: center; */
  border-radius: 35px !important;
}

.ant-select-dropdown {
  padding: 0px !important;
}

#product_form_prod_category_list {
  display: none !important;
}
