@import url("https://fonts.cdnfonts.com/css/google-sans");

* {
  font-family: Google Sans;
}

.customLayoutBG {
  background-color: #f5f4fa !important;
}

.searchDiv,
.topSearchDiv {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 10;
  background-color: white;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 500px;
  height: 56px;
  width: 340px;
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
}

.myStoreText {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0em;
  padding-left: 10px;
}

.statusToggle {
  border: 1px solid #cecbd4;
  min-width: 154px;
  height: 44px;
  border-radius: 40px;
  color: #30263e;
  padding-right: 0.3rem;
  padding-left: 0.8rem;
  background-color: transparent;
  background: transparent;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  line-height: 20px;
}

.countOnIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(92.54deg, #8800ff, #00b2ff);
  color: #fff;
  font-weight: bold;
  z-index: 2;
  width: 18px;
  height: 18px;
  font-size: 12px;
  border-radius: 50%;
  position: absolute;
  top: 12px;
  right: 6px;
}

.iconsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.hiddenScrollbar {
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
}

.customLayoutBackground,
.ant-layout,
:where(.css-dev-only-do-not-override-1adbn6x).ant-layout,
.ant-layout,
.css-dev-only-do-not-override-1adbn6x {
  background-color: none !important;
  background: none !important;
  padding: 0 !important;
}

:where(.css-dev-only-do-not-override-1adbn6x).ant-layout {
  background: #f5f4fa !important;
}

.userMenu {
  transform: rotate(90deg);
}

.iconButton {
  box-shadow: 0px 8px 24px 0px #422f9b1a;
  background-color: #ffffff;
}

.iconButtonArrow {
  box-shadow: 0px 8px 24px 0px #422f9b1a;
  color: #30263e;
  background-color: #ffffff;
  transform: scaleX(-1);
}

.iconStyle {
  color: #000000;
}

.toggleSwith {
  background: "linear-gradient(to right, #8800FF, #00B2FF)";
}

.headingDiv {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: flex-start;
  margin-top: 20px;
}

.breadcrumStyle {
  padding-left: 10px;
  padding-top: 5px;
}

.breadcrumbItem:hover {
  cursor: pointer;
}

.timelineStyle {
  font-family: Google Sans;
  font-size: 16px;
  opacity: 50%;
  color: #30263e;
  font-weight: 400;
  line-height: 20.35px;
  letter-spacing: 2%;
}

.custom-image-gallery .image-gallery-slides .image-gallery-image,
.custom-image-gallery .image-gallery-image {
  min-height: 580px !important;
  min-width: 580px !important;
}

.tryOnFilter {
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 20px;
}

.tryOnFilterAnchor {
  color: #4e4dff;
  font-family: Google Sans;
  font-size: 16px;
  font-weight: 700;
  text-decoration: underline;
}

.brandName {
  font-family: Google Sans;
  font-size: 24px;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #30263e;
}

.brandStyle {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.categoryDiv1 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Google Sans;
  font-weight: 500;
  font-size: 16px;
  box-shadow: 0px 10px 30px 0px #30263e0f;
  min-width: 75px;
  min-height: 35px;
  padding: 5px, 15px, 5px, 15px;
  border-radius: 40px;
  margin-left: 8px;
  color: #30263e;
}

.categoryDiv {
  border: 1px solid #cecbd4;
  border-radius: 40px;
  color: #30263e;
  background-color: transparent;
  background: transparent;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #30263e;
  width: 100px;
}

.productPriceStyle {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
}

.productPriceStyle span {
  font-family: Google Sans;
  font-size: 24px;
  opacity: 50%;
  color: #30263e;
  font-weight: 400;
  line-height: 30.53px;
  letter-spacing: 2%;
}

.productPriceDiv {
  font-family: Google Sans;
  font-size: 24px;
  font-weight: 700;
  line-height: 30.53px;
  letter-spacing: 2%;
  margin-left: 5px;
  color: #000000;
}

.descDiv {
  font-family: Google Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #30263e;
  margin-bottom: 10px;
}

.detailDescDiv {
  font-family: Google Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #30263e;
  opacity: 50%;
  margin-bottom: 10px;
}

.productImage {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
  box-shadow: 0px 10px 30px 0px #30263e0f;
  border: 2px solid #d8d8d8;
}

.cartCount {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 40px;
  height: 35px;
}

.CartButton,
.CartButton2 {
  border: none;
  background: none;
  cursor: pointer;
  height: 25px !important;
  width: 25px !important;
  background-color: #f5f4f5 !important;
  border-radius: 50% !important;
  box-shadow: none !important;
}

.CartButton:hover,
.CartButton2:hover {
  border: none !important;
  background: none !important;
  cursor: pointer;
  height: 25px !important;
  width: 25px !important;
  background-color: #f5f4f5 !important;
  border-radius: 50% !important;
  box-shadow: none !important;
  outline: none !important;
}

.countDisplay {
  width: 100%;
  text-align: center;
  margin: 0 10px;
  line-height: 40px;
  font-size: 14px;
  font-weight: 500;
  align-items: center;
  justify-content: center;
}

.buttonContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 10px 16px 0px rgba(48, 38, 62, 0.1);
}

.noBorder .MuiDataGrid-root {
  border: none !important;
}

.noBorder .MuiDataGrid-cell {
  border-bottom: none !important;
}

.dgRowDiv {
  display: flex;
  align-items: center;
  font-family: Google Sans;
  font-size: 14px;
  font-weight: 500;
  padding: 3px;
}

.dgRowDiv2 {
  display: flex;
  align-items: center;
  font-family: Google Sans;
  font-size: 16px;
  font-weight: 500;
  padding: 3px;
}

.dgRowDiv3 {
  display: flex;
  align-items: center;
  font-family: Google Sans;
  font-size: 16px;
  font-weight: 500;
  padding: 3px;
  justify-content: space-between;
  border: "1px solid red";
}

.addButton {
  background-color: #4e4dff;
  color: white;
  border: none;
  font-weight: 700;
  border-radius: 500px;
  cursor: pointer;
}

.borderStyleDiv {
  font-family: Google Sans;
  color: #30263e;
  font-size: 16px;
  font-weight: 500;
  padding: 15px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.commonDiv {
  display: flex;
  margin-top: 10px;
  margin-bottom: 30px;
}

/* .dg {
  border: 1px solid red !important;
} */

.colorBackground {
  background: var(--color-hash-code);
  height: 30px;
  display: flex;
  flex-direction: row;
}

.imageGalleryBox {
  width: "620px";
  height: "740px";
  background: "white";
  border-radius: "10px";
  margin-left: "15px";
}

.descGridStyle {
  width: "890px";
  height: "2027px";
  background: "white";
  border-radius: "10px";
  padding: "50px";
}

.headingDiv {
  font-size: "32px";
  font-weight: "700";
  line-height: "40.7px";
  margin-top: "10px";
  font-family: "Google Sans";
  margin-bottom: "10px";
}

.verSeperatorDiv {
  height: "25px";
  width: "2px";
  border-radius: "10px";
  border: "1px solid #EAE9EC";
}

.reviewDiv {
  margin-top: "50px";
  display: "flex";
  flex-direction: "row";
  margin-bottom: "30px";
  justify-content: "space-between";
}

.profilePicture {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: -10px;
  border: 2px solid white;
}

.profilePicturesContainer {
  display: flex;
}

.separator {
  pointer-events: none !important;
  cursor: default !important;
  color: grey;
  opacity: 50%;
}

.freelancerPic {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: 3px;
  border: 2px solid white;
}

.dropdownStyle {
  background-color: #ffffff;
  border-radius: 35px;
}

.formInput,
.formSelect,
.formTextArea,
.formInput:hover,
.formInput:active,
.formInput:focus,
.formInput:visited,
.formSelect,
.formSelect:hover,
.formSelect:active,
.formSelect:focus,
.formSelect:visited,
.formTextArea,
.formTextArea:hover,
.formTextArea:active,
.formTextArea:focus,
.formTextArea:visited {
  width: 100%;
  background-color: white !important;
  border-radius: 35px !important;
  height: 50px !important;
  font-size: 14px !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  border: none !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0rem 1rem !important;
  border: 3px solid #f5f4f5 !important;
}

.extendButton {
  background-color: #4e4dff;
  color: white;
  border: none;
  font-weight: 700;
  border-radius: 500px;
  cursor: pointer;
}

.addColorButton {
  border: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
  color: #4e4dff;
  font-weight: bold;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
  border-radius: 35px;
  margin-bottom: 10px;
}

.addColorButton:hover,
.addColorButton:active,
.addColorButton:focus,
.addColorButtonText:hover,
.addColorButtonText:active,
.addColorButtonText:focus {
  border: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
  color: #31263e;
  font-weight: bold;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
  border-radius: 35px;
}

.addColorButtonText {
  color: #4e4dff;
  font-weight: bold;
  font-size: 16px;
  text-decoration: underline;
}

.headingDiv {
  font-size: 32px;
  font-weight: 700;
  margin-top: 50px;
  font-family: "Google Sans";
  color: #30263e;
}

.headingDivReview {
  font-size: 32px;
  font-weight: 700;
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  font-family: "Google Sans";
  color: #30263e;
}

.checkoutButton {
  background-color: #4e4dff;
  width: 90%;
  color: white;
  border: none;
  font-weight: 700;
  border-radius: 500px;
  cursor: pointer;
  margin: 10px;
}

.inputBoxStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 1rem;
  border-radius: 10px;
  border: 0.5px solid rgba(48, 38, 62, 0.05);
  box-shadow: 0px 10px 30px 0px #30263e0f;
  background: #fff;
}

.inputFieldStyle {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 1rem !important;
  padding: 0.5rem;
  border-radius: 40px;
  border: 0.5px solid rgba(48, 38, 62, 0.05);
  box-shadow: 0px 10px 30px 0px #30263e0f;
  background: #fff;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.tableContainer {
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 24px;
  background-color: none !important;
  background: none !important;
  box-shadow: none !important;
  border: none !important;
  display: flex;
  align-items: center;
  flex-direction: column;
  /* border: 1px solid red !important; */
}

.tableBody {
  background-color: #ffffff;
  padding: 15px;

  height: 20px !important;
  border-radius: 5px !important;
}

.tableBody tr td {
  border-bottom: 1px solid #ddd;
}

.tableBody tr:last-child td {
  border-bottom: none;
}

.avatarBG {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: grey;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20px;
  font-weight: bold;
  font-family: sans-serif;
}

.cartSummary {
  border: 1px solid red !important;
  position: sticky !important;
  bottom: 0 !important;
  z-index: 1000 !important;
  background-color: white !important;
}
