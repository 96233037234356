.inputBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 1rem;
  border-radius: 40px;
  border: 0.5px solid rgba(48, 38, 62, 0.05);
  box-shadow: 0px 5px 8px 0px rgba(48, 38, 62, 0.05);
  background: #fff;
  width: 100%;
}

.filterIcon {
  box-shadow: none !important;
  border-radius: 40px !important;
  border: none !important;
  outline: none !important;
  background-color: white !important;
  min-height: 40px !important;
  margin-right: 0.5rem !important;
}
.filterIcon {
  transition: transform 0.3s ease;
}

.filterIcon.clicked {
  transform: rotate(90deg);
}

.inputField {
  flex: 1;
  padding: 10px 12px;
  font-size: 1rem;
  outline: none;
  border: none;
  width: 100%;
}

.elevatedElement {
  outline: none;
  border: none;
  box-shadow: 0px 5px 8px 0px rgba(48, 38, 62, 0.05);
}

.iconBtn {
  background-color: #fff;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  z-index: 1;
  box-shadow: 0px 5px 8px 0px rgba(48, 38, 62, 0.05);
  cursor: pointer;
}

@media screen and (max-width: 768px) {
}

.icon-container {
  cursor: pointer;
}

#leftHorizontalBar,
#rightHorizontalBar {
  transition: transform 0.3s ease;
}

#centerHorizontalBar {
  transition: transform 0.3s ease;
  border: 1px solid red;
}
.icon-container #leftHorizontalBar,
.icon-container #rightHorizontalBar {
  transform: translateY(-2px);
}
.icon-container #centerHorizontalBar {
  transform: translateY(2px);
}

/* .icon-container.active #leftHorizontalBar,
.icon-container.active #rightHorizontalBar {
  transform: translateY(-2px);
}

.icon-container.active #centerHorizontalBar {
  transform: translateY(1px);
} */

.icon-container.active #leftHorizontalBar,
.icon-container.active #rightHorizontalBar {
  transform: translateY(-8px);
}

.icon-container.active #centerHorizontalBar {
  transform: translateY(8px);
}

.image-preview {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 1rem;
}

.search-result-item {
  display: flex;
  align-items: center;
}

.content {
  margin-left: 15px;
}

.image-placeholder {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  color: #000;
  font-size: 14px;
  border-radius: 3px;
  margin-right: 1rem;
}

.image-preview {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 5px;
}

.highlighted {
  background-color: #f0f0f0;
}
