.drawer {
  /* width: 400px !important; */
}

.form {
  padding: 20px !important;
}

.encouragementText {
  font-size: 16px;
  color: #31263e !important;
  margin-bottom: 10px !important;
  padding: 0 16px !important;
  text-align: center !important;
}

.formItem {
  background-color: #fff !important;
  border-radius: 35px !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin-bottom: 16px !important;
  border: 2px solid #d0d0d0 !important;
  height: 40px !important;
}

.formItem > div > div > div > div > span > span > span {
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
  border-radius: 35px 0 0 35px !important;
  background-color: #fff !important;
  outline: none !important;
  box-shadow: none !important;
}

.formItem > div > div > div > div > span > span > input {
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
  border-radius: 0 35px 35px 0 !important;
  outline: none !important;
  box-shadow: none !important;
}

.formItem > div > div > div > div {
  border-radius: 35px !important;
}

.button {
  width: 100% !important;
  border-radius: 35px !important;
  height: 40px !important;
  font-size: 14px !important;
  font-weight: bold !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
  background-color: #31263e !important;
  color: white !important;
  border: none !important;
}
