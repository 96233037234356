.countOnIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(92.54deg, #8800ff, #00b2ff);
  color: #fff;
  font-weight: bold;
  z-index: 2;
  width: 18px;
  height: 18px;
  font-size: 12px;
  border-radius: 50%;
  position: absolute;
  top: 6px;
  right: 6px;
}

.newNotification {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red;
  color: white;
  font-weight: bold;
  z-index: 2;
  width: 30px;
  height: 18px;
  font-size: 10px;
  border-radius: 10px;
  position: absolute;
  top: 6px;
  right: 6px;
  animation: blink 1s infinite;
}

.iconsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.hiddenScrollbar {
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
}

.customLayoutBackground,
.ant-layout,
:where(.css-dev-only-do-not-override-1adbn6x).ant-layout,
.ant-layout,
.css-dev-only-do-not-override-1adbn6x {
  background-color: #f5f4fa !important;
  background: #f5f4fa !important;
}
:where(.css-dev-only-do-not-override-1adbn6x).ant-layout {
  background: #f5f4fa !important;
}

.userMenu {
  transform: rotate(90deg);
}
