.servicesContainer {
  background: #f5f4fa !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.serviceMainContainer {
  width: 90%;
  margin: 10px auto;
  margin-top: 110px;
}
.backColour {
  background: #f5f4fa !important ;
}

.breadcrumbContainer {
  background: #fff;
  width: 100%;
  margin: -20px 40px 2px -70px;
  z-index: 1000;
  position: fixed;
  min-width: auto;
  padding: 0px 0px 0px 100px;
}
.backBtnContainer {
  cursor: pointer;
  height: 30px;
  display: inline-block;
  position: fixed;
  z-index: 1000;
  align-items: start;
}

.categoriesContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: auto;
  width: 100%;
  gap: 10px;
  padding: 5px;
}

.categoriesContainer::-webkit-scrollbar {
  display: none;
}

.singleCategory {
  background-color: #fff;
  border-radius: 50px;
  z-index: 1;
  box-shadow: 0px 5px 8px 0px rgba(48, 38, 62, 0.05);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 20px;
  cursor: pointer;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 100px;
}

.serviceHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.actionsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.serviceListing {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  grid-gap: 2rem;
  margin-top: 10px;
  margin-bottom: 20px;
}
.backBtnContainer {
  /* height: 70px;
  width: 80px; */
  cursor: pointer;
  height: 30px;
  display: inline-block;
  position: fixed;
  z-index: 1000;
  /* top: 20px;
  left: 30px; */
  align-items: start;
}
.customLayoutBackground,
.ant-layout,
:where(.css-dev-only-do-not-override-1adbn6x).ant-layout,
.ant-layout,
.css-dev-only-do-not-override-1adbn6x {
  background-color: #f5f4fa !important;
  background: #f5f4fa !important;
}
:where(.css-dev-only-do-not-override-1adbn6x).ant-layout {
  background: #f5f4fa !important;
}
.hiddenScrollbar {
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
}

@media screen and (max-width: 900px) {
  .serviceMainContainer {
    margin-bottom: 15rem;
    margin-top: 0.5rem;
  }
}
