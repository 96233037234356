.storeContainer {
  background: #f5f4fa !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.servicesContainer {
  background: #f5f4fa !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.serviceMainContainer {
  width: 90%;
  margin: 10px auto;
  margin-top: 110px;
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}


@media screen and (max-width: 900px) {
  .serviceMainContainer {
    margin-top: 0.5rem;
    margin-bottom: 15rem;
  }
}
