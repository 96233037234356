.mapPageContainer {
  background: #f5f4fa !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.mapContainerRow {
  width: 100%;
  margin-bottom: 2rem;
  border: 5px solid white;
  border-radius: 15px;
  background-color: white;
}

.locationPicker {
  width: 95%;
  margin-left: auto;
  padding-bottom: 10px;
}

.map_container {
  height: 100vh;
  margin: 0;
  padding: 0;
}

.map_preview_container {
  height: 350px;
  border-radius: 15px;
  box-shadow: 0px 5px 8px 0px rgba(48, 38, 62, 0.05);
}

.marker_container {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0px 5px 8px 0px rgba(48, 38, 62, 0.05);
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.marker_container:hover {
  z-index: 2;
}

.mapmarker {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.services_container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* width: calc(91.5% - 200px); */
  width: calc(100% - 160px);
  background-color: transparent;
  bottom: 300px;
  overflow-x: scroll;
  padding: 30px 50px;
  margin-right: 75px;
  position: absolute;
  bottom: 10px;
}

.services_container::-webkit-scrollbar {
  display: none;
}

.service_mapcard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 150px;
  width: 400px;
  padding: 15px;
  border-radius: 20px;
  position: relative;
  margin-left: 30px;
  margin-right: 15px;
  background: #fff;
  box-shadow: 0px 5px 8px 0px rgba(48, 38, 62, 0.05);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.clicked_card {
  background: linear-gradient(92.54deg, #8800ff, #00b2ff);
  box-shadow: 0px 10px 16px 0px rgba(48, 38, 62, 0.3);
}

.clicked_card .mainTitle,
.clicked_card .subTitle,
.clicked_card .subTitle span,
.clicked_card .subTitle p {
  color: #fff !important;
}

.mapcard_img {
  width: 120px;
  height: 120px;
  border-radius: 20px;
  position: absolute;
  left: -15px;
  top: 15px;
}

.replacement_img {
  width: 95px;
  height: 95px;
  background: #eee;
  padding: 15px 10px 10px 15px;
}

.mapcard_details {
  padding-left: 105px;
  height: 100%;
}

.ends-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.start-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.detailsBtn {
  padding: 10px 5px;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 30px;
  cursor: pointer;
  width: 120px;
  background: rgb(78, 77, 255);
  font-weight: 500;
}

.activeDetailsBtn {
  background: #fff;
  color: rgba(78, 77, 255);
}

.mapRating {
  right: 10px !important;
  left: auto !important;
}

.provider_info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
  margin: 12px 0;
  width: 225px;
}

.closeMap,
.expandMap {
  position: absolute;
  right: 60px;
  top: 5px;
  scale: 90%;
  cursor: pointer;
}

.expandMap {
  right: 5px;
  top: 5px;
}
