.navBarContainer {
  background: #f5f4fa !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.content {
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: -50px 50px 20px 20px;
  flex-direction: column;
  font-size: small;
}

.breadcrumbContainer {
  padding-left: 90px;
  background: #fff;
  width: 100%;
  margin: -20px -60px -70px -100px;
  z-index: 1000;
  position: fixed;
  min-width: auto;
  padding-left: 140px;
}
