.postElementsRow {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 1rem;
  max-width: 100%;
  min-width: 520px;
}

.postImage {
  width: 100%;
  background-size: cover;
  border-radius: 20px;
}

.richDesc {
  color: #30263e;
  line-height: 1rem;
  word-spacing: 0.05rem;
  letter-spacing: 0.025rem;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  height: fit-content;
  max-height: 60px;
}

.postDesc {
  margin: 10px 0;
}

.expandDesc {
  max-height: fit-content;
}

.readMore {
  color: #31263e;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  text-decoration: underline;
  width: fit-content;
  margin: 0;
}

.postActions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin: 5px;
  height: 400px;
}

.shareIconMainPage {
  cursor: pointer;
  margin-bottom: 5px;
}

.shareIconMainPage:hover {
  transform: scale(1.2);
}

.postIconsColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: auto;
  gap: 20px;
  cursor: pointer;
}

.authorInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.postImageWrapper {
  position: relative;
  width: 100%;
  height: 300px;
  overflow: hidden;
}

.postImage.coverMode {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.ant-modal-root-class-post-card {
  border: 1px solid red !important;
}
