.googleBtn {
  width: 100%;
  min-width: 180px;
  display: flex;
  background: black;
  border-radius: 38px;
  font-weight: 700;
  color: #fff;
  align-items: center;
  justify-content: space-evenly;
  padding: 5px;
}
