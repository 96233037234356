.drawerInput {
  height: 40px;
  border-radius: 67px;
  box-shadow: 1px 2px 5px #808080ad !important;
  width: 100%;
}

.drawerInput .ant-select-selector,
.drawerInput input {
  border-radius: 67px !important;
  width: 100%;
}

.drawerInput:hover,
.drawerInput:active,
.drawerInput:focus {
  box-shadow: 1px 2px 5px #808080ad !important;
}

.newAddress {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(78, 77, 255, 0.1) !important;
  padding: 5px 10px;
  padding-left: 15px;
  border-radius: 67px;
}

.InputNumberDrawer {
  /* border: 1px solid red !important; */
  display: flex !important;
  align-items: center !important;
}
