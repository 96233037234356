.orderContainer {
  background: #f5f4fa !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.serviceMainContainer {
  width: 90%;
  margin: 10px auto;
  margin-top: 110px;
}
.serviceHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.actionsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}
.actionsContainerNext {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.searchDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  border: none !important;
  background-color: white;
  border: 1px solid red;
  top: 2rem;
  right: 1rem !important;
  position: absolute;
}

.calendarButton {
  border: 2px solid #cecbd4;
  min-width: 100px;
  border-radius: 35px;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  color: #30263e;
  background-color: transparent;
  background: transparent;
  font-weight: bold;
}

.searchInput {
  border: none !important;
  outline: none !important;
  background-color: white;
  width: 100%;
  box-shadow: none !important;
}

.filterButton {
  color: black !important;
  background-color: white;
  border-radius: 20px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  border: none !important;
}
.filterButton.active {
  background-color: #4e4dff;
  color: white !important;
  font-weight: bold;
}

.countingSpan {
  background: white;
  padding: 0.1rem 0.5rem;
  border-radius: 35px;
  color: #30263e;
  font-weight: bold;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin-left: 0.2rem;
}

.Cartitem {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 1rem;
  background-color: white;
  padding: 16px;
  border-radius: 15px;
  /* background-image: url("/src//assests/gradient-liquid-abstract-background_23-2148909370.avif"); */
  background-size: cover; /* This ensures the background covers the entire div */
  background-repeat: no-repeat; /* This prevents the image from repeating */
  background: linear-gradient(151deg, #ecd8ff 0%, #d8f3ff 60.52%) !important;
}

.modalContent {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.centeredPosition {
  justify-content: center;
}

.topPosition {
  justify-content: flex-start;
  padding-top: 20px;
}

.searchIconInsideInput {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  color: #ccc;
}

.searchIconMain {
  background-color: white !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 35px !important;
  height: 40px !important;
  width: 40px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0.2rem;
  font-size: 20px;
}

.closeIcon {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.resultsContainer {
  width: 90%;
  min-width: 300px;
  height: calc(100vh - 100px);
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-bottom: 20px;
  margin-top: 4.5rem;
}

.resultsContainer.singleCard > div > div {
  min-width: 300px !important;
}

.resultsContainer::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.noResultsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.noResultsIcon {
  font-size: 50px;
  color: white;
}

.noResultsText {
  font-size: 20px;
  color: white;
}

.cardImage {
  max-height: 150px !important;
  object-fit: cover !important;
  min-height: 150px !important;
}

.cardImageWrapper {
  position: relative;
}

.cardImage {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.capsule {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  border-radius: 10px;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 10px;
  gap: 10px;
}

.capsuleItem {
  padding: 5px 10px;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 8px;
}

@media (max-width: 768px) {
  .resultsContainer {
    flex-wrap: wrap;
  }
}
