.contractCard {
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 16px;
  font-family: "Arial", sans-serif;
}

.cardHeader {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 16px;
}

.userInfo {
  flex-grow: 1;
}

.userName {
  margin: 0;
  color: #333;
  font-size: 16px;
  font-weight: bold;
}

.userDetails {
  color: #666;
  font-size: 14px;
}

.expiryNotice {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #e53e3e;
}

.clockIcon,
.warningIcon {
  margin-right: 4px;
}

.daysLeft {
  font-weight: bold;
  margin-right: 4px;
}

.cardBody {
  border-top: 1px solid #f0f0f0;
  padding-top: 12px;
  color: #333;
}

.category,
.shareProduct,
.earned {
  font-size: 14px;
  margin-bottom: 8px;
}

.cardFooter {
  display: flex;
  justify-content: space-between;
  padding-top: 12px;
}

.endButton,
.extendButton {
  border: none;
  border-radius: 20px;
  padding: 6px 20px;
  font-size: 14px;
  cursor: pointer;
}

.endButton {
  background: #f44336;
  color: white;
}

.extendButton {
  background: #4caf50;
  color: white;
}

.viewMoreButton {
  display: block;
  width: 100%;
  text-align: center;
  padding: 12px;
  font-size: 16px;
  color: #1a73e8;
  background: none;
  border: none;
  cursor: pointer;
}

.expmainDiv {
  background-color: white;
  padding: 5px;
  border-radius: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
