.refundModal .MuiDialog-paperWidthSm,
.refundModal .MuiPaper-root .MuiDialog-paperWidthSm {
  background: linear-gradient(151deg, #ecd8ff 0%, #d8f3ff 60.52%) !important;
  border-radius: 35px;
  padding: 25px;
}

.refundModal .MuiModal-backdrop {
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.136) !important;
}
