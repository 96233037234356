.categoriesContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  width: 90%;
  margin: auto;
  padding-bottom: 10px;
}

.CategoriesTermAndConditionsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 20%;
  margin-bottom: 1rem;
}
.CategoriesTermAndConditionsContainer p {
  text-decoration: underline;
  color: grey;
  cursor: pointer;
}

.modalRoot {
  /* border: 1px solid red !important; */
}

.closeCatButton {
  border: none !important;
  outline: none !important;
  background-color: #31253e !important;
  color: white !important;
  min-height: 40px !important;
  min-width: 120px !important;
  border-radius: 35px !important;
  font-weight: bold !important;
  font-size: 14px !important;
}

.closeCatButton:hover,
.closeCatButton:focus,
.closeCatButton:active {
  border: none !important;
  outline: none !important;
  background-color: #4e4dff !important;
  color: white !important;
}

.exploreCatButton {
  border: none !important;
  outline: none !important;
  background-color: #4e4dff !important;
  color: white !important;
  min-height: 40px !important;
  min-width: 120px !important;
  border-radius: 35px !important;
  font-weight: bold !important;
  font-size: 14px !important;
}

.exploreCatButton:hover,
.exploreCatButton:focus,
.exploreCatButton:active {
  border: none !important;
  outline: none !important;
  background-color: #31253e !important;
  color: white !important;
}

.continueCatButton {
  border: 1px solid #31253e !important;
  outline: none !important;
  background-color: white !important;
  color: #31253e !important;
  min-height: 40px !important;
  min-width: 120px !important;
  border-radius: 35px !important;
  font-weight: bold !important;
  font-size: 14px !important;
}

.continueCatButton:hover,
.continueCatButton:focus,
.continueCatButton:active {
  border: none !important;
  outline: none !important;
  background-color: #4e4dff !important;
  color: white !important;
}

.catModalFooter {
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  flex-direction: row;
  width: 90%;
  margin: auto;
}

.tncCheckbox > span > span {
  /* border: 1px solid red !important; */
  border-radius: 35px !important;
  height: 20px !important;
  width: 20px !important;
}
