.HomeRoleContainer {
  opacity: 0.15;
  background: linear-gradient(160deg, #8200ff 0%, #00b0ff 68.93%);
  display: flex;
  height: 100vh;
  overflow: hidden;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
}
.floatingCardContainer {
  display: flex;
  position: absolute;
  overflow: hidden;
  top: 10%;
  /* height: 100vh; */
  width: 100%;
  align-items: center;
  flex-direction: column;
}
.floatingRoleCardContainer {
  display: flex;
  width: 90%;
  margin-left: 5%;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}

@media (max-width: 850px) {
  /* … */
  .floatingRoleCardContainer {
    display: flex;
    width: 100% !important;
    margin-left: 0 !important;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
  }
}
