.roleCard {
  flex-direction: row;
  align-items: center;
  border-radius: 40px;
  /* background: linear-gradient(180deg, #fff 40%, rgba(255, 255, 255, 0) 100%); */
  background: linear-gradient(
    90deg,
    #ffffff 36.39%,
    rgba(255, 255, 255, 0) 107.88%
  );

  width: 80%;
  display: flex;
  /* border: 1px solid grey; */
  height: 120px;
  justify-content: space-evenly;
  flex-shrink: 0;
  color: black;
  margin: 8px;
  z-index: 100;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  cursor: pointer;
}

.roleCard > img {
  cursor: pointer;
}

.roleType {
  color: black;
  z-index: 1000;
  /* font-weight: bold; */
}

.clickIMG {
  /* border: 1px solid red; */
}

.withLogoCotainer {
  /* background: url("../../assests/smaillCircleBG.png"); */
  justify-content: center;
  display: flex;
  background-position: center;
  height: 30%;
  background-position: center;
  z-index: 344;
  align-items: center;
  background-origin: border-box;
  background-repeat: no-repeat;
  /* width: 100%; */
}

@media (max-width: 1500px) {
  .hideSupplier {
    display: none !important;
  }
}
