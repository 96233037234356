/* EKYC.module.css */

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: relative;
  z-index: 1;
  padding: 20px;
  text-align: center;
}

.backgroundVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  transition: opacity 1s;
}

.verificationContainer {
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 24px;

  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(6.5px);
  -webkit-backdrop-filter: blur(6.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.verificationText {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
  color: #31263e;
}

.confirmationList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
}

.description {
  font-size: 16px;
  margin-bottom: 24px;
  color: #31263e;
}

.iconText {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}

.iconText p {
  margin-left: 8px;
  font-size: 14px;
  color: #31263e;
}

.nextBtn {
  width: 40%;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
  margin-top: 20px;
  background-color: #1677ff !important;
  border-radius: 35px !important;
}

.proceedBtn {
  width: 100%;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
  margin-top: 20px;
  background-color: #1677ff !important;
  border-radius: 35px !important;
}

.proceedBtnDisabled {
  width: 100%;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
  margin-top: 20px;
  background-color: gray !important;
  color: white !important;
  border-radius: 35px !important;
  opacity: 0.5;
}

.proceedBtnDisabled:hover {
  background-color: gray !important;
}

.nextBtn:hover,
.proceedBtn:hover {
  background-color: #31263e !important;
}

.previousBtn {
  position: absolute;
  top: 20px;
  left: 5%;
  z-index: 2;
  background-color: #31263e;
  color: white;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}

.previousBtn:hover {
  background-color: white !important;
  border: 1px solid #31263e !important;
  color: #31263e !important;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}

.buttonContainer {
  margin-top: 20px;
}

.kycLink {
  text-decoration: none;
}

.animationContainer {
  display: flex;
  justify-content: space-around;
  margin-bottom: 24px;
}

.animationWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.iconDescription {
  font-size: 14px;
  margin-top: 8px;
  color: #fff;
}

.confirmation {
  margin-top: 20px;
  margin-bottom: 24px;
  font-size: 14px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
}

.confirmation input {
  margin-right: 8px;
  cursor: pointer;
}

.skipBtn {
  position: absolute;
  top: 20px;
  right: 5%;
  z-index: 2;
  background-color: #31263e;
  color: white;
  border-radius: 20px;
}

.skipBtn:hover {
  background-color: #1677ff !important;
  border: 1px solid #31263e !important;
  color: white !important;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
}
