.ant-modal-content {
    background-color: none !important;
    box-shadow: none !important;
  }
  
  .successModalContent,
  .failedModalContent {
    position: relative;
    padding: 24px;
    border-radius: 15px;
    height: 500px;
    /* width: 80%; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
  }
  
  .successModalContent::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background-image: url("../../../assests/confettiIMG.png"),
      linear-gradient(
        169deg,
        rgba(242, 230, 255, 1) 0%,
        rgba(237, 237, 255, 1) 35%,
        rgba(232, 244, 255, 1) 100%
      ); */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    filter: blur(2.5px);
    z-index: -1;
  }
  
  .successModalContent > * {
    position: relative;
    z-index: 100;
  }
  
  .successModalIcon {
    color: #52c41a;
    font-size: "40px";
  }
  .confetti {
    height: 100%;
    width: 100%;
    /* margin-top: 2rem;
    margin-left: 2rem; */
  }
  