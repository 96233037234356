.socialMediaContainer {
  width: 100% !important;
  margin-bottom: 20px !important;
}

.socialMediaField {
  display: flex !important;
  align-items: center !important;
  width: 100% !important;
  justify-content: center !important;
  margin-bottom: 10px !important;
}

.icon {
  font-size: 24px !important;
  background-color: white !important;
  border-radius: 50% !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  padding: 10px !important;
  margin-right: 15px !important;
}

.urlDisplay {
  display: flex !important;
  align-items: center !important;
  font-size: 16px !important;
  margin-right: 15px !important;
  background-color: white !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  padding: 5px 10px !important;
  width: 100% !important;
  border-radius: 35px !important;
}

.inputField,
.inputField:focus,
.inputField:active,
.inputField:hover,
.inputField:visited {
  margin-left: 5px !important;
  border: 2px solid #ddd !important;
  border-radius: 0 35px 35px 0 !important;
  padding: 5px !important;
  font-size: 16px !important;
  height: 16px !important;
  box-shadow: none !important;
  width: 100% !important;
}

.selectField {
  margin-top: 10px !important;
}

.removeButton {
  margin-left: 10px !important;
  color: red !important;
}

.addButton {
  border-radius: 35px !important;
  background-color: #31253e !important;
  color: white !important;
  padding: 5px 10px !important;
  font-size: 14px !important;
  margin-left: 80% !important;
}

@media screen and (max-width: 768px) {
  .socialMediaField {
    flex-direction: column !important;
    align-items: flex-start !important;
  }

  .icon {
    margin-bottom: 5px !important;
  }

  .inputField {
    margin-left: 0 !important;
    margin-top: 5px !important;
  }

  .removeButton {
    margin-left: 0 !important;
  }
}
