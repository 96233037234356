.quill {
  display: flex;
  flex-direction: column-reverse;
  min-height: 100px !important;
}
.ql-toolbar {
  background: #f5f4f5;
  border-radius: 35px;
}

.ql-toolbar,
.ql-container {
  border: none !important;
}

.quill {
  border: 1px solid #f5f4f5;
  padding: 0.6rem 0.5rem;
  background: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 15px;
}
.ql-editor {
  min-height: 60px;
  /* border: 1px solid red; */
  margin-bottom: 1rem;
}

/* .ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: "";
  background-image: url("src/assests/FontSizeIcon.svg");
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  height: 16px; 
  width: 16px;
}

.ql-snow .ql-picker.ql-header .ql-picker-label,
.ql-snow .ql-picker.ql-header .ql-picker-item {
  width: auto;
  text-indent: -9999px; 
} */
