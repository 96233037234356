.termsAndConditionsContainer {
  padding: 3px 10px;
  text-align: justify;
  /* height: 400px; */
  margin-top: 10px;
}
.termsAndConditionsHeading {
  padding: 3px 10px;
  text-align: justify;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1250px) {
  .ant-modal .ant-modal-content {
    background: red;
    max-width: 100px !important;
  }
}
