.ant-select-disabled:where(
    .css-dev-only-do-not-override-1adbn6x
  ).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: none !important;
  background: white !important;
}
.ant-select-disabled:where(
    .css-dev-only-do-not-override-1adbn6x
  ).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
}
