/* ReviewList.module.css */
.reviewCard {
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 20px;
  font-family: "Arial", sans-serif;
}
.reviewList {
  margin-top: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 16px;
  margin-bottom: 20px;
  font-family: "Arial", sans-serif;
}
.reviewHeader {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.avatar {
  margin-right: 12px;
}

.reviewInfo {
  flex-grow: 1;
}

.reviewDate {
  color: #999;
  font-size: 14px;
  margin-bottom: 4px;
}

.reviewerName {
  color: #333;
  font-weight: 600;
  font-size: 16px;
}

.reviewRating {
  display: flex;
  align-items: center;
  color: #ffa534;
  font-weight: bold;
}

.starIcon {
  margin-right: 4px;
}

.reviewContent {
  color: #666;
  font-size: 14px;
  line-height: 1.5;
}

.reviewReply {
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid #eee;
  color: #666;
  font-size: 14px;
  position: relative;
}

.seeReplyButton {
  position: absolute;
  right: 16px;
  top: 16px;
  border: none;
  background-color: transparent;
  color: #1a73e8;
  padding: 4px 8px;
  font-size: 14px;
  cursor: pointer;
}

.seeReplyButton:hover {
  text-decoration: underline;
}

.viewMoreButton,
.showLessButton {
  display: block;
  margin: 20px auto;
  color: #1a73e8;
  background-color: transparent;
  border: none;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
}

.viewMoreButton:hover,
.showLessButton:hover {
  text-decoration: underline;
}

.buttonContainer {
  text-align: center;
}
