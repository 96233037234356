.banner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #4e4dff;
  color: #fff;
  padding: 8px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1100;
  animation: slideIn 0.5s ease-out;
  border-bottom: 1px solid #ddd;
}

@keyframes slideIn {
  from {
    top: -100px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.actions {
  display: flex;
  gap: 8px;
  margin-right: 1.5rem;
}

.allowButton {
  background-color: #31263e;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 35px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.allowButton:hover {
  background-color: #544a61;
}

.closeButton {
  background-color: #fff;
  color: #31263e;
  border: none;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.closeButton:hover {
  background-color: #aaa;
}

.message {
  font-size: 0.85rem;
  font-weight: 500;
  margin: 0;
  line-height: 1.2;
}
