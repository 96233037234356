.drawerInput {
  height: 40px;
  border-radius: 67px;
  box-shadow: 1px 2px 5px #808080ad !important;
  width: 100%;
}
.drawerInput:disabled {
  height: 40px;
  border-radius: 67px;
  box-shadow: 1px 2px 5px #808080ad !important;
  width: 100% !important;
}

.drawerInput .ant-select-selector,
.drawerInput input {
  border-radius: 67px !important;
  width: 100%;
}

.drawerInput:hover,
.drawerInput:active,
.drawerInput:focus {
  box-shadow: 1px 2px 5px #808080ad !important;
}

.newAddress {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(78, 77, 255, 0.1) !important;
  padding: 5px 10px;
  padding-left: 15px;
  border-radius: 67px;
}

.duration-picker {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 35px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
  padding: 5px !important;
}

.duration-box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  padding: 5px 8px;
  width: 120px;
}

.duration-box div:nth-child(1) {
  background-color: #4e4dff !important;
  color: white !important;
  border-radius: 15px !important;
}

.duration-box div:nth-child(1) .anticon-down {
  color: white !important;
  fill: white !important;
}

.serviceInner {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
}

.serviceRadio {
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 35px;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  padding: 5px;
  transition: height 0.3s ease-in-out;
  height: 30px;
  margin-top: 10px;
}

/* .serviceRadio .text {
  display: inline-block;
  transition: max-height 0.3s ease-in-out;
  max-height: 1.6em;
  overflow: hidden;
  position: relative;
}

.serviceRadio .text::after {
  content: "...";
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: white;
  padding-left: 4px;
  z-index: 1;
} */

.serviceRadio .text {
  width: 100%;
}

.serviceRadio span.ant-radio + * {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.eyeAddOne {
  border: 1px solid #ccc;
  padding: 0.14rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ccc;
}

.eyeAddOne svg {
  color: white;
}

.serviceRadio.active .text {
  max-height: none;
}

.serviceRadio.active .text::after {
  content: "";
}

.addedAddressDrawer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}
.addedAddressDrawer p {
  display: flex;
  flex-direction: column;
  height: 80px;
  gap: 5px;
  margin-bottom: 2rem;
}
.addedAddressDrawer p span:nth-child(2) {
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 5px;
  border-radius: 5px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.drawerInputAd {
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 35px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
}
