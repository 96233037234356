.customImageGallery {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    border: 5px solid #30263E05;
    border-radius: 5px;
    margin-top: 10px;
    object-fit: contain;
}

.customImageGallery img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.navigationButtons {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
    width: 100%;
    z-index: 1;
}

.thumbnailContainer {
    /* overflow-x: scroll; */
    white-space: nowrap;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    margin-top: 10px;
    margin-bottom: 20px;
    object-fit: contain;
}

.thumbnailContainer1 {
    overflow-x: scroll;
    white-space: nowrap;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    margin-top: 10px;
    margin-bottom: 20px;
    object-fit: contain;
}

.thumbnailContainer1 img {
    width: 90px;
    height: 90px;
    margin-right: 10px;
    border-radius: 5px;
}

.thumbnailContainer img {
    width: 90px;
    height: 90px;
    margin-right: 10px;
    border-radius: 5px;
}

.activeThumbnail {
    border: 2px solid #30263E05;
    height: 80px;
    width: 80px;
}

.hiddenScrollbar {
    &::-webkit-scrollbar {
      display: none;
    }
  
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

/* .thumbnailContainer {
    &::-webkit-scrollbar {
        width: 3px !important;
        height: 9px;
    }

    &::-webkit-scrollbar-thumb {
        background: grey;
        opacity: 50%;
        border-radius: 5px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-track-piece {
       height: 5px;
       width: 5px;
    }
} */

.iconButton {
    background-color: rgba(255, 255, 255, 0.5);
    width: 50px;
    height: 50px;
    border: 1px solid #30263E26;
}


.iconStyle {
    box-shadow: 0px 0px 20px 0px #30263E33;
    border: 2.94px solid #30263E;
    width: 5.88px;
    height: 11.76px;
}