.backgroundWrapper {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  cursor: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"%3E%3Cdefs%3E%3ClinearGradient id="grad" x1="0%25" y1="0%25" x2="100%25" y2="100%25"%3E%3Cstop offset="0%25" style="stop-color:%23a855f7;stop-opacity:1"%3E%3C/stop%3E%3Cstop offset="100%25" style="stop-color:%233b82f6;stop-opacity:1"%3E%3C/stop%3E%3C/linearGradient%3E%3C/defs%3E%3Cpath fill="url(%23grad)" stroke="%23FFF" stroke-width="1" d="M5.5 3.21V20.8c0 .45.54.67.85.35l4.86-4.86a.5.5 0 0 1 .35-.15h6.87a.5.5 0 0 0 .35-.85L6.35 2.85a.5.5 0 0 0-.85.35Z"%3E%3C/path%3E%3C/svg%3E'),
    auto;
}

.backgroundVideo {
  position: absolute;

  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -2;
  transition: opacity 1s ease;
}

/* .octopusCursor {
  position: absolute;
  width: 64px;
  height: 64px;
  background-image: url("https://development.theoctopus.ai/static/media/OctopusLikeButton.525016f3c2e0daa8bdd0936ccb10e59c.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  pointer-events: none;
  z-index: 1000;
  transition: transform 0.2s ease;
  animation: float 4s ease-in-out infinite;
  -webkit-backface-visibility: hidden;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
} */

@keyframes float {
  0% {
    transform: translate(-15px, -15px) rotate(-20deg);
  }
  50% {
    transform: translate(15px, 15px) rotate(-20deg);
  }
  100% {
    transform: translate(-15px, -15px) rotate(-20deg);
  }
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(1.6px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  animation: overlayFadeIn 0.4s ease-in-out;
}

.modalContainer {
  width: 420px;
  padding: 30px;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(12px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  text-align: center;
  animation: scaleUp 0.4s ease-in-out;
}

.modalTitle {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #31263e;
  font-weight: bold;
  background: linear-gradient(90deg, #4c3f61, #b395c7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.modalMessage {
  font-size: 1.1rem;
  color: #444;
  margin-bottom: 1.8rem;
  line-height: 1.6;
}

.modalButton {
  background: linear-gradient(90deg, #31263e, #4c3f61);
  color: white;
  border: none;
  padding: 12px 28px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: background 0.3s ease, transform 0.2s ease;
}

.modalButton:hover {
  background: linear-gradient(90deg, #4c3f61, #31263e);
  transform: scale(1.08);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.bold {
  font-size: 1.2rem;
  color: #4c3f61;
}

@keyframes scaleUp {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes overlayFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
