.blueSection {
  width: auto;
  border-radius: 20px;
  height: auto;
  background: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
}
.imgDiv {
  margin-top: 20px;
  margin-left: 25px;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  border: 5px solid rgb(199, 189, 199);
}
.profileTextSec {
  margin-top: 15px;
  font-size: 14px;
  margin-left: 25px;
  font-weight: 500;
}
/* .descDiv{
    font-size: 16px;
    margin-left:25px;
} */
.freelancerNameText {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  font-weight: 500;
}
.bigDiv {
  width: fit-content;
  height: auto;
  margin-top: 25px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  background-color: white;
  border: none;
  border-radius: 20px;
  box-shadow: 0px 8px 8px  #c4c5c7;
}
.smallDiv h2 {
  margin: 10px;
}
.smallDiv {
  border-radius: 25px;
  padding: 10px;
  align-items: center;
  width: fit-content;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 12px;
  font-weight: 300;
  color: grey;
  border: none;
  padding-right: 10px;
  padding-left: 10px;
  background-color: white;
}
.collabDiv {
  background-color: #f5f4fa;
  font-weight: bolder;
  align-items: center;
  padding-top: 4px;
  box-shadow: 5px;
  height: 20px;
  width: 170px;
  margin-top: 15px;
  color: rgb(45, 218, 18);
  margin-left: 25px;
  text-align: center;
  border-radius: 20px;
  border: 2px gray solid;
}
.noCollabDiv {
  background-color: #f5f4fa !important;
  font-weight: bolder;
  align-items: center;
  padding-top: 4px;
  box-shadow: 5px;
  height: 20px;
  color: red;
  width: 170px;
  margin-top: 15px;
  margin-left: 25px;
  text-align: center;
  border-radius: 20px;
  border: 2px gray solid;
}

/* .categoryButton {
  background-color: #c4c5c7;
  color: rgb(52, 50, 50);
  border: 2px solid white;
  font-weight: 500;
  border-radius: 15px;
  border: none;
  height: 35px;
  width: 100%;
  margin-left: 25px;
  margin-top: 15px;
} */
 .categoryButton {
 background-color: #c4c5c7;
  color: rgb(52, 50, 50);
  font-weight: 500;
  border-radius: 15px;
  border:none;
  height: 35px;
  width: 100%;
  margin-left: 0; /* Reset margin-left to zero */
  margin-top: 15px;
  display: block; /* Ensure buttons take full width */
 }
.followButton {
  border: none;

  width: 120px;
  height: 30px;
  border-radius: 40px;
  border-color: #1677ff;
  background-color: #4e4dff;
  color: white;
  /* margin-top: 15px; */
  margin-left: 25px;
  font-weight: 500;
  margin-bottom: 25px;
}
.followingButton {
  border: none;

  width: 120px;
  height: 30px;
  border-radius: 40px;
  border-color: gray;
  background-color: gray;
  color: white;
  word-spacing: 2fr;
  /* margin-top: 15px; */
  margin-left: 25px;
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 25px;
}
.socialDiv {
  /* margin-top: 15px;
    margin-left: 50px;
    di */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.imagesBox {
  margin-left: 15px;
  align-items: center;

  height: 150px;
  width: 150px;
  border-radius: 5px;
}
.postsImages {
  display: flex;
  flex-direction: row;
  gap: 10px;
}


.NoDataFound {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 0px;
  height: 100%;
}