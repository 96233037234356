.verifyOtpContainer {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.verifyOtpContainerGuest {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.otpInputGroup {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.otpInputWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #f5f4f5;
  border-radius: 40px;
  width: 85px;
  height: 50px;
  box-shadow: 0px 10px 30px 0px rgba(48, 38, 62, 0.06);
}

.otpInputWrapperGuest {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #f5f4f5;
  border-radius: 40px;
  height: 50px;
  box-shadow: 0px 10px 30px 0px rgba(48, 38, 62, 0.06);
}

.otpInput,
.otpInput:focus,
.otpInput:active,
.otpInput:hover {
  border: none !important;
  outline: none !important;
  border-radius: 50px !important;
  text-align: center !important;
  font-size: 18px !important;
  width: 50px !important;
  height: 50px !important;
  /* color: #f5f4f5 !important; */
  font-weight: bold !important;
  font-size: 26px !important;
}

.FormInput,
.FormInput:focus,
.FormInput:active,
.FormInput:hover {
  border: none !important;
  outline: none !important;
  width: 100%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
  border-radius: 50px !important;
  padding: 10px !important;
  font-size: 16px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.FormInput > input,
.FormInput:focus > input,
.FormInput:active > input,
.FormInput:hover > input {
  padding-left: 10px !important;
}

.FormInputNumber,
.FormInputNumber:focus,
.FormInputNumber:active,
.FormInputNumber:hover {
  border: none !important;
  outline: none !important;
  border-radius: 50px !important;
}

.emailVerifyInput {
  border-radius: 35px !important;
  border: 2px solid #ccc !important;
  text-align: center !important;
  font-size: 18px !important;
  width: 50px !important;
  height: 50px !important;
}

.emailVerifyInput:focus,
.emailVerifyInput:hover,
.emailVerifyInput:active {
  border-radius: 35px !important;
  border: 2px solid #ccc !important;
  text-align: center !important;
  font-size: 18px !important;
  width: 50px !important;
  height: 50px !important;
}

.emailVerifyInputRoot,
.emailVerifyInputRoot:focus,
.emailVerifyInputRoot:active {
  border: 2px solid #ccc !important;
}

.buttonBG {
  background-color: #4e4dff !important;
  color: #fff !important;
  border-radius: 50px !important;
  padding: 10px 40px !important;
  font-size: 16px !important;
}

.updatePhoneNumber {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-bottom: 10px !important;
}

.remainingTimeEmailVerify {
  margin-top: 10px !important;
  font-size: 16px !important;
  color: red !important;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .otpInputGroup {
    gap: 8px;
    max-width: 350px;
  }

  .emailVerifyInput {
    width: 45px !important;
    height: 45px !important;
    font-size: 16px !important;
  }

  .buttonBG {
    font-size: 14px !important;
    padding: 8px 30px !important;
  }

  .verifyOtpContainer {
    padding: 0 !important;
  }
}

@media (max-width: 480px) {
  .otpInputGroup {
    gap: 5px;
    max-width: 300px;
  }

  .emailVerifyInput {
    width: 40px !important;
    height: 40px !important;
    font-size: 14px !important;
  }

  .buttonBG {
    font-size: 13px !important;
    padding: 6px 25px !important;
  }
}
