.textArea {
    width: 100%;
    background-color: white !important;
    border-radius: 15px !important;
    height: 100px !important;
    font-size: 14px !important;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
    border: none !important;
    display: flex !important;
    align-items: center !important;
    /* justify-content: center !important; */
    padding: 0rem 0.2rem !important;
    border: 3px solid #f5f4f5 !important;
}

.generalStyle {
    height: 40px;
    border-radius: 20px;
}

.submitButton {
  background-color: #4e4dff;
  color: white;
  border: none;
  border-radius: 35px;
  cursor: pointer;
  height: 35px;
  width: 100px;
}
.submitButton:hover,
.submitButton:active {
  background-color: #fff !important;
  color: #30263e !important;
  border: 2px solid #4e4dff !important;
  border-radius: 35px;
  margin-right: 10px;
  height: 35px;
  width: 100px;
  cursor: pointer;
}


.formInput,
.formSelect,
.formInput:hover,
.formInput:active,
.formInput:focus,
.formInput:visited,
.formSelect,
.formSelect:hover,
.formSelect:active,
.formSelect:focus,
.formSelect:visited {
  width: 100%;
  background-color: white !important;
  border-radius: 35px !important;
  height: 40px !important;
  font-size: 14px !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  border: none !important;
  display: flex !important;
  align-items: center !important;
  /* justify-content: center !important; */
  padding: 0rem 1rem !important;
  border: 3px solid #f5f4f5 !important;
}

.phno {
    border:none !important;
}

.formInput_phno {
    width: 100%;
    background-color: white !important;
    margin-top: 30px;
    border-radius: 35px !important;
    height: 40px !important;
    font-size: 14px !important;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
    border: none !important;
    display: flex !important;
    align-items: center !important;
    /* justify-content: center !important; */
    padding: 0rem 1rem !important;
    border: 3px solid #f5f4f5 !important;
}