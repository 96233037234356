.smallImage {
  width: 15px;
  height: 15px;
  padding-bottom: -80px;
}
.rightText {
  font-size: 18px;
  font-weight: 100;
}
.weekNameMargin {
  padding-left: 30%;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-right: 30%;
  color: black;
}
.weekName {
  /* width: 100px;
  height: 20px;
  border-radius: 15px;
  text-align: center;
  color:black;
  padding: 5px 5px; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #cad0d7; /* Set background color as needed */
  color: red; /* Set text color to black */
  padding: 10px; /* Adjust padding as needed */
  border-radius: 5px; /* Add border-radius for rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add box shadow for a subtle lift effect */
}

.submitButton {
  width: 100px;
  height: 30px;
  border-radius: 40px;
  border-color: #1677ff;
  background-color: #4e4dff;
  color: white;
  padding: 19.5px, 50px, 19.5px, 50px;
}
.smallDiv {
  background: #f5f4fa !important;
  display: block;
  border-radius: 8px;
  border: 1px #cdcdd4 solid;
  width: 92%;
  height: 60px;
  background-color: white;
  display: block;
  justify-items: center;
}
.bigDiv {
  border-right: 0.5px solid grey;
}
.addBreak {
  text-decoration: underline;
}

.flexContainer {
  display: flex;
  align-items: center;
}

.divider {
  flex: 1;
  height: 1px;
  color: #b6b4c1;
  background-color: #000; /* Set divider color */
}

.content {
  width: 100px;
  text-align: center;
  height: 22px;
  border-radius: 15px;
  background-color: #fff; /* Set content background color */
  color: gray; /* Set content text color */
  /* Adjust content padding as needed */
  background-color: #e0dada;
  font-weight: bold;
}
