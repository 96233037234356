.bankAccountContainer {
  /* max-height: 60vh; */
  /* overflow-y: scroll; */
  width: 100%;
  padding: 10px;
  /* scroll-behavior: smooth; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.bankAccountContainer > form {
  width: 80%;
}

input {
  /* background: #fff !important; */
}
