.blogCardContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  gap: 10px;
}

.blogImage {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.blogInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  padding-bottom: 10px;
}
