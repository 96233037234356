.ant-modal .ant-modal-content {
  /* width: 510px !important; */
  /* border-radius: 0px !important; */
  /* height: 100vh !important; */
}

.statusText {
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  border-radius: 15px;
  height: 30px;
}

.statusUpcoming {
  background-color: #fef8eb;
  color: #faa800;
}

.statusInProgress {
  background-color: #f1f1ff;
  color: #4e4dff;
}

.statusCompleted {
  background-color: #eefbf6;
  color: #35ce92;
}

.statusCancelled {
  background-color: #fff1f1;
  color: #ff4d4d;
}

.statusRefund {
  background-color: #fff1f1;
  color: #ff4d4d;
  width: 200px;
}

.billing-summary {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.billing-summary h4 {
  margin-bottom: 1rem;
}

.billing-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  color: #97929e; /* Grey color for text */
}

.billing-item-title {
  /* Regular font-weight for titles */
}

.billing-amount {
  text-align: right;
  color: #97929e; /* Grey color for currency */
  font-weight: bold;
}

.billing-digit {
  color: black;
  font-weight: bol;
}

.total-amount {
  font-weight: bold; /* Only Total amount is bold */
  color: black; /* Default color for total amount */
}

.short-id {
  display: inline-block;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.short-id:hover {
  max-width: initial;
  background-color: #f0f0f0; /* Light background on hover */
  padding: 0.2rem 0.5rem;
  border-radius: 15px;
}

@media (max-width: 768px) {
  .billing-summary {
    /* Responsive styles */
  }
}
