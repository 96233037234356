.BackgroundWhiteBox {
  border-radius: 20px;
  background: white;
  box-shadow: 0px 5px 8px 0px rgba(48, 38, 62, 0.05);
  padding: 20px;
  margin-bottom: 20px;
  position: relative;
}

.BackgroundWhiteBox_big {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 5px 8px 0px rgba(48, 38, 62, 0.05);
  padding: 20px;
  margin-bottom: 20px;
  margin-right: 20px;
  position: relative;
}

.navButtons {
  display: none;
}

.BackgroundWhiteBox:hover .navButtons {
  display: block;
}

.BackgroundWhiteBox_big:hover .navButtons {
  display: block;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header h2 {
  margin: 0;
}

.header b {
  text-decoration: underline;
}

.seeAllEnd {
  display: flex;
  align-items: center;
  justify-content: center;
}

.seeAll {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
